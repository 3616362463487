<template>
  <div class="grid grid-cols-5 gap-9 gap-y-3">
    <div class="col-span-2">{{ productSizeDescription() }}</div>
    <div class="col-span-3" title="height x width">{{ productSize }} cm</div>

    <div class="col-span-2">Image size</div>
    <div class="col-span-3" title="height x width">{{ imageSize }} cm</div>

    <div class="col-span-5 typeset-8">Sizes are approximate</div>

    <div v-if="!isFramed" class="col-span-5 typeset-8">
      This print comes with a border containing the artist name, print title and publishing details near the bottom edge
      of the paper.
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useProductStore } from '../../stores/useProductStore'

const { activeProduct, isFramed } = storeToRefs(useProductStore())

const productSize = computed(() => {
  const height = Math.round(activeProduct.value?.dimensions.height / 10)
  const width = Math.round(activeProduct.value?.dimensions.width / 10)

  return `${height} × ${width}`
})

function productSizeDescription() {
  if (isFramed.value) {
    return 'Framed size'
  } else {
    return 'Paper size'
  }
}

const imageSize = computed(() => {
  const height = Math.round(activeProduct.value?.dimensions.image_height / 10)
  const width = Math.round(activeProduct.value?.dimensions.image_width / 10)

  return `${height} × ${width}`
})
</script>
