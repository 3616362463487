<template>
  <div v-if="getIsLoggedIn">
    <div class="button button-primary" @click="showCreateModal">Create wish list</div>
  </div>

  <KmModal :open="show" @close="closeModal">
    <div class="modal-bg p-2">
      <div class="bg-white w-full md:w-1/3 p-4 mx-auto">
        <div class="flex justify-between">
          <h3>Create Wish List</h3>
        </div>

        <div class="flex flex-col">
          <h6 class="mt-4">Title of wish list</h6>
          <input v-model="title" class="" type="text" />

          <h6 class="mt-4">Description</h6>
          <textarea v-model="description" class="" rows="10" />
          <div class="flex flex-row justify-between my-4">
            <button class="button w-1/2 mr-2" @click="closeModal">cancel</button>
            <button class="button bg-black text-white w-1/2 ml-2" @click="addNewList">Add</button>
          </div>
        </div>
      </div>
    </div>
  </KmModal>
</template>

<script lang="ts" setup>
import { useSessionStore } from './stores/useSessionStore'
import { onBeforeMount, ref } from 'vue'
import KmModal from './components/general/KmModal.vue'
import useWishList from './hooks/useWishList'

const { getIsLoggedIn } = useSessionStore()

const show = ref(false)
const title = ref('')
const description = ref('')

onBeforeMount(async () => {
  getIsLoggedIn().then((res) => {
    if (!res) {
      console.log('Not logged in')
    }
  })
})

function closeModal() {
  show.value = false
}

function showCreateModal() {
  show.value = true
}

async function addNewList() {
  const { addNewList } = useWishList()
  addNewList(title.value, description.value)
    .then((res) => res)
    .catch((error) => {
      alert('Error creating new wish list: ' + error)
    })
}
</script>
