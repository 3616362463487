<template>
  <div v-if="artwork.customer_rating > 3.5" class="flex cursor-pointer leading-5" @click="scrollToReviews()">
    <div v-for="icon in ratingStars()" :key="icon.id">
      <div class="pr-1" :title="hoverTitle()">
        <KmIcon :name="icon" class="h-3 w-3" />
      </div>
    </div>
    <span class="underscore-hover typeset-8 pt-[1px]">(read reviews)</span>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useProductStore } from '../../stores/useProductStore'
import KmIcon from '../../components/general/KmIcon.vue'

const { artwork } = storeToRefs(useProductStore())

function ratingStars() {
  const starIcons = []

  for (let star = 1; star <= 5; star++) {
    let iconName = 'star-o'
    if (this.artworkRating() >= star) iconName = 'star'
    if (this.artworkRating() < star && this.artworkRating() >= star - 0.5) iconName = 'star-half'

    starIcons.push(iconName)
  }

  return starIcons
}

function artworkRating() {
  return Math.ceil(artwork.value.customer_rating * 2) / 2
}

function hoverTitle() {
  return `${artworkRating()} out of 5`
}

function scrollToReviews() {
  window.scrollTo(0, document.getElementById('reviews-section').offsetTop)
}
</script>
