<template>
  <form class="form-container">
    <div v-if="error" class="flash-notice p3 text-center">Something went wrong please try again</div>
    <label>Email</label>
    <input v-model="email" autocomplete="username" placeholder="Email" type="email" />

    <label>Password</label>
    <input v-model="password" autocomplete="current-password" placeholder="Password" type="password" />

    <button class="button button-primary w-full mt-4" @click.prevent="login">Login</button>
  </form>

  <div class="flex mt-4 justify-between">
    <a href="/register">Create account</a>
    <a href="/password">Forgot password</a>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useSessionStore } from '../../stores/useSessionStore'

const email = ref('')
const password = ref('')
const error = ref(false)
const { getLoginModal } = useSessionStore()

const emit = defineEmits<{
  (e: 'close'): void
}>()

function closeModal() {
  emit('close')
  getLoginModal()
}

function csrfToken() {
  return document.querySelector('meta[name=csrf-token]').content
}

async function login() {
  error.value = false
  const { login } = useSessionStore()
  const headers = { 'X-CSRF-Token': csrfToken() }
  const data = {
    email: email.value,
    password: password.value
  }

  login(data, headers)
    .then((response) => {
      console.log(response)
      if (response.includes('account')) {
        closeModal()
      } else {
        console.log('error in login modal')
        error.value = true
      }
    })
    .catch((error) => {
      console.log('error in login modal', error)
      // better errors go here.
    })
}
</script>
