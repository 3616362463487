<template>
  <div v-if="activeOptions" class="w-full border-t">
    <div class="flex justify-between border-b py-4">
      <div class="flex">
        <span>Size:</span>
        <KmSelectBox v-if="showSelect.sizes" :options="sizeKmOptions()" @option-update="selectUpdated" />
        <div v-else>
          <span class="pl-2">{{ sizeCategoryDescription() }}</span>
        </div>
      </div>
      <KmIcon v-if="!getViewInARoom" name="expand" class="h-6 w-6 cursor-pointer" @click="toggleViewInARoom()"></KmIcon>
    </div>

    <div class="border-b py-4">
      <div class="flex justify-between">
        <div>Frame: {{ selectedTitle(activeProduct.frame, 'No frame') }}</div>
        <KmIcon name="help" class="h-6 w-6 cursor-pointer" @click="toggleFrameModal()"></KmIcon>
      </div>

      <div v-if="showSelect.frames" class="my-3">
        <div class="flex">
          <div
            v-for="f in activeOptions.frames"
            :key="f.frame_id"
            :class="{ '!border-black': isFrameSelected(f), 'border-white': !isFrameSelected(f) }"
            class="frame-icon"
            @click="$emit('updateOptions', f.product_id)"
          >
            <img v-if="f.image_url" :src="f.image_url" :alt="f.title" :title="f.title" />
            <div v-else class="button button-grey cursor-pointer !h-[36px]" data-test="unframed-option">
              <span class="hidden sm:inline-block whitespace-nowrap">No frame</span>
              <span class="inline-block sm:hidden">None</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-between border-b py-4 mb-5">
      <div class="flex">
        <span>Mount:</span>
        <KmSelectBox v-if="showSelect.mounts" :options="mountKmOptions()" @option-update="selectUpdated" />
        <div v-else>
          <span class="pl-2">{{ describeFromMount(activeProduct.mount) }}</span>
        </div>
      </div>
      <KmIcon v-if="showMountHelp" name="help" class="h-6 w-6 cursor-pointer" @click="toggleMountModal()"></KmIcon>
    </div>

    <button v-if="adding" class="button button-primary w-full">
      <KmIcon name="spinner" class="h-4 w-4 mr-2 animate-spin"></KmIcon> Adding…
    </button>

    <button
      v-else
      id="add-to-cart-button"
      class="button button-primary w-full button-sticky-mobile"
      data-test="cart-button"
      @click="$emit('addToCart', activeProduct.id)"
    >
      <span v-if="added"><KmIcon name="tick" class="h-4 w-4 pt-1 mr-2"></KmIcon> Added to basket</span>
      <span v-else>
        {{ addToCartButtonText }}
      </span>
    </button>

    <KmModal :open="frameModalState" @close="toggleFrameModal">
      <KmFrameHelp />
    </KmModal>

    <KmModal :open="mountModalState" @close="toggleMountModal">
      <KmMountHelp :mounts="activeOptions.mounts" />
    </KmModal>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useCartStore } from '../../stores/useCartStore'
import { useProductStore } from '../../stores/useProductStore'
import KmFrameHelp from '../general/KmFrameHelp.vue'
import KmIcon from '../general/KmIcon.vue'
import KmModal from '../general/KmModal.vue'
import KmMountHelp from '../general/KmMountHelp.vue'
import KmSelectBox from '../general/KmSelectBox.vue'
import type { Frame, Mount, Product, ProductOptions, Size } from '../../interfaces/ProductInterfaces'
import type { KmSelectBoxOption } from '../../interfaces/ComponentInterfaces'
import usePrices from '../../hooks/usePrices'
import useProductDetails from '../../hooks/useProductDetails'

const props = defineProps<{
  artworkProductList: Product[]
  activeOptions: ProductOptions
  activeProduct: Product
}>()

const { displayPrice } = usePrices()
const { describeFromDimensions, getSizeDescription, isFramed } = useProductDetails()
const { toggleViewInARoom, getViewInARoom } = useProductStore()
const { adding, added } = storeToRefs(useCartStore())
const frameModalState = ref(false)
const mountModalState = ref(false)

const emit = defineEmits<{
  (e: 'updateOptions', productId: string): void
  (e: 'addToCart', productId: string): void
  (e: 'showScale', productId: string): void
}>()

const addToCartButtonText = computed(() => {
  const price = displayPrice(props.activeOptions.selected.price)
  const framed = isFramed(props.activeProduct) ? 'Framed' : 'Unframed'
  return `£${price} - ${framed} - Add to basket`
})

const showSelect = computed(() => {
  if (props && props.activeOptions) {
    return {
      sizes: checkSelect(props.activeOptions.sizes),
      mounts: checkSelect(props.activeOptions.mounts),
      frames: checkSelect(props.activeOptions.frames)
    }
  } else {
    return {
      sizes: false,
      mounts: false,
      frames: false
    }
  }
})

function checkSelect(list?: Size[] | Mount[]) {
  if (list) {
    if (list.length === 0) return false
    if (list.length === 1 && list[0].product_id === props.activeProduct.id) return false
    return true
  } else {
    return false
  }
}

function isFrameSelected(frame: Frame) {
  const product = props.activeProduct
  if (frame && frame.title && product.frame && product.frame.title) {
    return frame.title.toLowerCase() === product.frame.title.toLowerCase()
  } else if (frame && product.frame) {
    return frame.title === product.frame.title
  } else if (frame?.title == 'no frame') {
    return product.frame == null
  } else {
    return false
  }
}

function selectedTitle(item: Frame | Mount, base: string) {
  return item ? item.title : base
}

function sizeKmOptions() {
  const current = props.activeOptions.selected.product_id
  return props.activeOptions.sizes.map((item) => {
    const description = item.description
      ? `${item.title.replace(/^x+\s\w|^\w/, (match) => match.toUpperCase())} (${getSizeDescription(
          item.product_id,
          props.artworkProductList
        )})`
      : 'No description'
    return mapKmOptions(item, current, description)
  })
}

function sizeCategoryDescription() {
  const product = props.activeProduct
  const dimensions = describeFromDimensions(product.dimensions)
  if (product.size_category_label) {
    return `${product.size_category_label} (${dimensions})`
  } else {
    return dimensions
  }
}

function mountKmOptions() {
  const current = props.activeOptions.selected.product_id
  return props.activeOptions.mounts.map((item) => {
    const description = item.description ? `${item.title.charAt(0).toUpperCase()}${item.title.slice(1)}` : 'No mount'
    return mapKmOptions(item, current, description)
  })
}

function mapKmOptions(item: Size | Mount, currentId: string, description: string): KmSelectBoxOption {
  return {
    value: item.product_id,
    description: description,
    selected: currentId === item.product_id
  } as KmSelectBoxOption
}

function describeFromMount(mount?: Mount) {
  return mount ? mount.title : 'No mount'
}

function toggleFrameModal() {
  frameModalState.value = !frameModalState.value
  handleBackground(frameModalState.value)
}

function handleBackground(isOpen: boolean) {
  const root = document.documentElement
  if (isOpen == true) {
    root.classList.add('modal-open')
  } else {
    root.classList.remove('modal-open')
  }
}

function toggleMountModal() {
  mountModalState.value = !mountModalState.value
  handleBackground(mountModalState.value)
}

const selectUpdated = (option: KmSelectBoxOption) => {
  emit('updateOptions', option.value)
}

const showMountHelp = computed(() => props.activeOptions.mounts.length > 1)
</script>
