<template>
  <svg class="km-icon" :class="'icon-' + name" :style="cssStyle">
    <use :xlink:href="'#icon-' + name"></use>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

/**
 * KmIcon renders named svg icons which exist in the DOM (in a script tag which is set to display:none).
 * These icons are generated by Rails `helper:svg_sprite_sheet` and are injected server side for every
 * page of the application
 *
 * todo: maybe only render icons used on the page?
 * todo: consider defaults instead of the size being rendered through a global style tag
 *
 * The size of the icons is also rendered from the server side and is expanded in the class `.km-icon` which
 * presents in a `<style>.kmicon={}</style>` tag
 */
export default defineComponent({
  props: {
    name: { type: String, required: true },
    cssStyle: { type: String, required: false, default: '' }
  }
})
</script>
