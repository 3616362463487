import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "col-span-6",
  style: {"min-height":"50vw"}
}
const _hoisted_2 = { class: "relative w-full" }
const _hoisted_3 = {
  class: "tag-xmas top-0",
  style: {"bottom":"inherit"}
}
const _hoisted_4 = { class: "grid grid-cols-2 mb-10" }
const _hoisted_5 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      ($setup.getViewInARoom)
        ? (_openBlock(), _createBlock($setup["KmViewInARoom"], {
            key: 0,
            src: $props.src
          }, null, 8, ["src"]))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("div", _hoisted_2, [
        _createVNode($setup["ProductGalleryMain"]),
        _withDirectives(_createElementVNode("div", _hoisted_3, "Christmas exclusive!", 512), [
          [_vShow, $setup.isTier3]
        ])
      ], 512), [
        [_vShow, !$setup.getViewInARoom]
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("a", {
          href: $props.src,
          "data-fancybox": "",
          class: "button -mr-[1px]"
        }, "Enlarge", 8, _hoisted_5),
        _createElementVNode("button", {
          class: "button cursor-pointer",
          onClick: $setup.showScale
        }, [
          ($setup.getViewInARoom)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode($setup["KmIcon"], { name: "expand" }),
                _cache[0] || (_cache[0] = _createElementVNode("span", { class: "pl-2" }, "View full", -1))
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode($setup["KmIcon"], { name: "expand" }),
                _cache[1] || (_cache[1] = _createElementVNode("span", { class: "pl-2" }, "View at scale", -1))
              ], 64))
        ])
      ])
    ]),
    _createVNode($setup["KmModal"], {
      "full-size": $setup.fullSize,
      open: $setup.zoom,
      onClose: $setup.closeZoom
    }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode($setup["KmImageZoom"], { src: $props.src }, null, 8, ["src"]), [
          [_vShow, $setup.zoom]
        ])
      ]),
      _: 1
    }, 8, ["full-size", "open"])
  ], 64))
}