import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative deep-inline" }
const _hoisted_2 = {
  ref: "content",
  class: "overflow-y-hidden line-clamp-4"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ], 512),
    ($setup.enabled)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "mt-2 underscore cursor-pointer block",
          onClick: $setup.toggle
        }, _toDisplayString($setup.linkText()), 1))
      : _createCommentVNode("", true)
  ]))
}