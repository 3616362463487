<template>
  <div>
    <KmIcon name="heart" class="h-4 w-4 -mb-1 cursor-pointer" @click="addProduct()"></KmIcon>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useSessionStore } from './stores/useSessionStore'
import KmIcon from './components/general/KmIcon.vue'
import useTracking from './hooks/useTracking'

const { getIsLoggedIn } = useSessionStore()
const { loginModal, wishListModal, wishlistProductId, wishlistProductType } = storeToRefs(useSessionStore())
const { trackAddToWishlist } = useTracking()

const props = defineProps<{
  productId: string
  productType?: string
}>()

function addProduct() {
  wishlistProductId.value = props.productId
  wishlistProductType.value = props.productType || 'Product'

  trackAddToWishlist(wishlistProductId.value)

  getIsLoggedIn(true).then((result) => {
    if (result) {
      loginModal.value = false
      wishListModal.value = true
    } else {
      loginModal.value = true
      wishListModal.value = false
    }
  })
}
</script>
