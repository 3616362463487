<template>
  <div class="grid grid-cols-5 gap-x-9 gap-y-3">
    <template v-for="(data, idx) in displayMetaData" :key="idx">
      <div class="col-span-1">{{ data.key }}</div>
      <div class="col-span-4">{{ data.value }}</div>
    </template>
  </div>

  <div v-if="showProductDisclaimer" class="col-span-3 typeset-8 mt-4">
    <p>Actual product may differ slightly to image above. Some prints contain writing in the border area.</p>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { isEmpty, reject } from 'lodash-es'
import { storeToRefs } from 'pinia'
import { useProductStore } from '../../stores/useProductStore'

const { activeProduct, artwork, isPod, isSkuProduct, isTier1, isUnique, podMedia } = storeToRefs(useProductStore())

const mediaDescription = computed(() => {
  const media = podMedia?.value?.find(function (medium) {
    return medium.id == activeProduct.value?.media_id
  })

  if (!media) return

  // HACK: Prefix description with 'Fine art giclée' unless the artist is one of the following
  // SEE: https://3.basecamp.com/3471886/buckets/2435956/todos/7101651256
  if (['andy-warhol', 'jean-michel-basquiat', 'keith-haring', 'robert-indiana'].includes(artwork.value.artist.slug)) {
    return media.media_desc_long
  } else {
    return `Fine art giclée print. ${media.media_desc_long}`
  }
})

const displayMetaData = computed(() => {
  const metadata = artwork.value?.metadata
  const glassDescription = activeProduct.value?.glass?.long_description
    ? activeProduct.value.glass.long_description
    : ''

  function hangingKitDescription() {
    // Don't show hanging description for a couple of Julia Opie prints
    if (![473067, 473068].includes(artwork.value.id)) {
      return 'Our bespoke hand-made frames arrive complete with D-rings and picture cord or strap hangers for larger items, attached. We also include a hanging kit with every framed order, which contains a wall plug and matching screw, and an instruction manual, so that you can decorate your walls the moment your prints arrive.'
    }
  }

  const metaDetails = [
    { key: 'Media', value: mediaDescription.value ? mediaDescription.value : '' },
    { key: 'Medium', value: metadata?.medium ? metadata.medium : '' },
    { key: 'Date', value: metadata?.date ? metadata.date : '' },
    { key: 'Paper', value: metadata?.paper ? metadata.paper : '' },
    { key: 'Edition', value: metadata?.edition ? metadata.edition : '' },
    { key: 'Condition', value: metadata?.condition ? metadata.condition : '' },
    { key: 'Framing', value: metadata?.framing ? metadata.framing : '' },
    { key: 'Glazing', value: glassDescription },
    { key: 'Hanging Kit', value: glassDescription ? hangingKitDescription() : '' }
  ]

  return reject(metaDetails, function (item) {
    return isEmpty(item.value)
  })
})

const showProductDisclaimer = computed(() => {
  if (isSkuProduct.value || isUnique.value || isPod.value || isTier1.value) {
    return false
  } else {
    return true
  }
})
</script>
