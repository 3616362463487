import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid grid-cols-1 md:grid-cols-2 gap-8"
}
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "typeset-8" }
const _hoisted_5 = { class: "leading-6" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.frames)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.frames, (frame) => {
          return (_openBlock(), _createElementBlock("div", {
            key: frame.id
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                src: frame.image_url,
                alt: "",
                class: "h-20 w-20 mr-6"
              }, null, 8, _hoisted_3),
              _createElementVNode("div", null, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(frame.title), 1),
                _createElementVNode("p", _hoisted_5, _toDisplayString(frame.description), 1)
              ])
            ])
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}