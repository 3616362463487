// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import type { Artwork, Product } from './apps/main/src/interfaces/ProductInterfaces'
import useGA4 from '../../../utils/useGA4'

export interface useTracking {
  trackAddItem: (product: Product, artwork: Artwork) => any
  trackAddToWishlist: (productId: string) => any
}

export default function useTracking() {
  const { trackAddToWishlistGA4, trackEventGA4 } = useGA4()

  async function trackAddItem(product: Product, artwork: Artwork) {
    if (product.type === 'StandardPrintProduct' || product.type === 'CustomPrintProduct') {
      window.KM.KlaviyoHelper.trackAddProductToCart(product, artwork)
      trackEventGA4('add_to_cart', artwork)
    }

    if (product.type === 'SkuProduct') {
      trackEventGA4('add_to_cart', artwork)
    }
  }

  async function trackAddToWishlist(productId: string) {
    trackAddToWishlistGA4(productId)
  }

  return {
    trackAddItem,
    trackAddToWishlist
  }
}
