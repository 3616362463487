import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.artwork.customer_rating > 3.5)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "flex cursor-pointer leading-5",
        onClick: _cache[0] || (_cache[0] = $event => ($setup.scrollToReviews()))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.ratingStars(), (icon) => {
          return (_openBlock(), _createElementBlock("div", {
            key: icon.id
          }, [
            _createElementVNode("div", {
              class: "pr-1",
              title: $setup.hoverTitle()
            }, [
              _createVNode($setup["KmIcon"], {
                name: icon,
                class: "h-3 w-3"
              }, null, 8, ["name"])
            ], 8, _hoisted_1)
          ]))
        }), 128)),
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "underscore-hover typeset-8 pt-[1px]" }, "(read reviews)", -1))
      ]))
    : _createCommentVNode("", true)
}