import type { Dimensions, Product } from '../interfaces/ProductInterfaces'
import { get } from 'lodash-es'

export default function useProductDetails(): {
  describeFromDimensions: (dimensions?: Dimensions) => string
  getSizeDescription: (productId: string, list: Product[]) => string
  hasMount: (product: Product) => boolean
  isFramed: (product: Product) => boolean
  isPaper: (product: Product) => boolean
  isPod: (product: Product) => boolean
  isSkuProduct: (product: Product) => boolean
  isTier1: (retailTierId: number) => boolean
  isTier3: (retailTierId: number) => boolean
  isUnique: (categories: string[][]) => boolean
  isUnframed: (product: Product) => boolean
  parseProductId: (locationHash: string) => string | null
} {
  function describeFromDimensions(dimensions?: Dimensions) {
    if (!dimensions) return 'Unknown size'
    return `${mm_to_cm(dimensions.height)} × ${mm_to_cm(dimensions.width)} cm`
  }

  function getSizeDescription(productId: string, list: Product[]): string {
    if (!list) return 'Unknown size'
    const product = list.find((item) => productId == item.id)
    return describeFromDimensions(product?.dimensions)
  }

  function hasMount(product: Product): boolean {
    return get(product, 'mount.id', 0) > 0
  }

  function isFramed(product: Product): boolean {
    return get(product, 'frame.id', 0) > 0 || get(product, 'group') === 'framed print'
  }

  function isPaper(product: Product): boolean {
    const paperMediaIds = [0, 1, 2, 3, 10, 12, 13]
    return paperMediaIds.includes(get(product, 'media_id'))
  }

  function isPod(product: Product): boolean {
    const podMediaIds = [1, 2, 3, 10, 12, 13]
    return podMediaIds.includes(get(product, 'media_id'))
  }

  function isSkuProduct(product: Product): boolean {
    return get(product, 'type') === 'SkuProduct'
  }

  function isTier1(retailTierId: number): boolean {
    return retailTierId === 1
  }

  function isTier3(retailTierId: number): boolean {
    return retailTierId === 3
  }

  function isUnframed(product: Product): boolean {
    return isPaper(product) && !isFramed(product)
  }

  function isUnique(categories: string[][]): boolean {
    return categories.flat().includes('unique')
  }

  function mm_to_cm(mm: number) {
    return Math.round(mm / 10)
  }

  // Can this be trusted for all productIds?
  function parseProductId(locationHash: string): string | null {
    const pattern = /\d+(::(((frame|glass|media|mount):\d+|((air|border|mount-width):(\d+,?)+))(_)?)+(size:\d+,\d+)?)?/
    const output = pattern.exec(locationHash)
    return output ? output[0] : null
  }

  return {
    describeFromDimensions,
    getSizeDescription,
    hasMount,
    isFramed,
    isPaper,
    isPod,
    isSkuProduct,
    isTier1,
    isTier3,
    isUnframed,
    isUnique,
    parseProductId
  }
}
