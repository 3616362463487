<template>
  <div v-if="itemAdded">
    <p class="pt-4">Item added to ‘{{ selectedList.description }}’</p>
    <a :href="wishlistHref()" class="button w-full">Go to wish list</a>
  </div>

  <div v-if="listsLoading" class="loading h-12 -mb-8"></div>

  <div v-if="!itemAdded && !listsLoading">
    <p class="mt-5">{{ message }}</p>

    <div v-if="addingNew || !hasWishLists">
      <div class="form-container my-5">
        <label>Title</label>
        <input v-model="listTitle" placeholder="Enter title" type="text" />

        <label>Description</label>
        <textarea v-model="listDescription" placeholder="Enter description" type="text"></textarea>
      </div>

      <div class="flex gap-x-f flex-row gap-x-8">
        <button v-if="hasWishLists" class="w-full button button-tall" @click="addingNew = false">Cancel</button>
        <button class="w-full button button-primary" @click="createWishList">Create wish list</button>
      </div>
    </div>

    <div v-else>
      <div class="flex border-b py-3">
        <div>List:</div>
        <KmSelectBox v-if="hasWishLists" :options="wishListOptions()" @option-update="updateOption" />
      </div>

      <button class="button button-primary w-full mt-8" @click="addItemToWishList">Add</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { clone, find, first, isEmpty, some } from 'lodash-es'
import { computed, onMounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useSessionStore } from '../../stores/useSessionStore'
import KmSelectBox from './KmSelectBox.vue'
import useWishList from '../../hooks/useWishList'

import type { KmSelectBoxOption } from '../../interfaces/ComponentInterfaces'

defineProps<{
  productId?: string
  productType?: string
}>()

defineEmits<{
  (e: 'close'): void
  (e: 'updateOptions', productId: string): void
  (e: 'optionUpdate', option: KmSelectBoxOption): void
}>()

const { addToWishList, addNewList } = useWishList()
const { fetchWishLists } = useSessionStore()
const { wishLists, wishlistProductId, wishlistProductType } = storeToRefs(useSessionStore())
const addingNew = ref(false)
const itemAdded = ref(false)
const listDescription = ref('')
const listsLoading = ref(true)
const listTitle = ref('')
const message = ref('')

onMounted(() => {
  fetchWishLists().then(function () {
    listsLoading.value = false
  })
})

watch(wishLists, () => {
  let selectedListId

  if (localStorage.getItem('selectedListId')) {
    try {
      selectedListId = JSON.parse(localStorage.getItem('selectedListId')!)
      selectList(selectedListId)
    } catch (e) {
      // ignore error
    }
  }

  if (wishLists.value.length > 0 && !some(wishLists.value, { selected: true })) {
    selectedListId = first(wishLists.value).id
    selectList(selectedListId)
  }
})

const hasWishLists = computed(() => {
  return wishLists.value.length > 0
})

const selectedList = computed(() => {
  return find(wishLists.value, ['selected', true])
})

function wishListOptions() {
  const newOption = {
    id: '_NEW_',
    description: '+ Create new wish list',
    selected: false
  }

  // clone list to avoid recursive updates on reactive property:wishLists
  const clonedList = clone(wishLists.value)
  clonedList.push(newOption)

  return clonedList
}

function wishlistHref() {
  return `/gallery/${selectedList.value.id}`
}

function addItemToWishList() {
  listsLoading.value = true
  addToWishList(selectedList.value.id, wishlistProductId.value, wishlistProductType.value).then((response) => {
    if (response === 200) {
      itemAdded.value = true
      listsLoading.value = false

      // Change "Wish list name (3 items)" -> "Wish list name"
      selectedList.value.description = selectedList.value.description.split('(')[0].trim()
    }
  })
}

function createWishList() {
  if (isEmpty(listTitle.value)) {
    message.value = 'Please enter a title'
    return
  }

  listsLoading.value = true
  addNewList(listTitle.value, listDescription.value).then(async (response) => {
    if (response.status !== 200) {
      message.value = response.data
      return
    }

    message.value = 'Wish list created'
    listTitle.value = ''
    listDescription.value = ''
    localStorage.setItem('selectedListId', JSON.stringify(response.data.id))

    fetchWishLists().then(() => {
      addingNew.value = false
      listsLoading.value = false
      selectList(response.data.id)
    })
  })
}

const updateOption = (option: KmSelectBoxOption) => {
  if (option.id === '_NEW_') {
    addingNew.value = true
    return
  }

  localStorage.setItem('selectedListId', JSON.stringify(option.id))
  selectList(option.id)
}

function selectList(id) {
  wishLists.value.map((wishList) => {
    if (wishList.id === id) {
      wishList.selected = true
    } else {
      wishList.selected = false
    }
  })
}
</script>
