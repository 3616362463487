import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "col-span-6 flex flex-col" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "mt-4" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "col-span-3 typeset-8" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("a", {
      class: "col-span-3",
      href: $setup.productUrl()
    }, [
      _createElementVNode("img", {
        src: $setup.itemUrl($props.item.image_url)
      }, null, 8, _hoisted_2)
    ], 8, _hoisted_1),
    _createElementVNode("div", _hoisted_3, [
      ($setup.artist)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: `/prints/${$setup.artist.slug}`
          }, _toDisplayString($setup.artist.full_name), 9, _hoisted_4))
        : _createCommentVNode("", true),
      _createElementVNode("a", {
        href: $setup.productUrl()
      }, [
        _createElementVNode("div", {
          innerHTML: $setup.itemTitle()
        }, null, 8, _hoisted_6)
      ], 8, _hoisted_5),
      _createElementVNode("div", _hoisted_7, [
        _createTextVNode(_toDisplayString($setup.framedText) + " £" + _toDisplayString($setup.displayPrice($props.item.price)) + " ", 1),
        ($props.item.quantity > 1)
          ? (_openBlock(), _createElementBlock("span", _hoisted_8, "× " + _toDisplayString($props.item.quantity), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", {
        class: "text-right cursor-pointer",
        "data-test": "delete-button",
        onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('deleteItem', $props.item.id)))
      }, "Remove")
    ])
  ], 64))
}