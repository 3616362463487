import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  href: "/prints/new"
}
const _hoisted_2 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tags", { 'clamp-tags': $props.clamp }])
  }, [
    ($setup.isArtworkNew())
      ? (_openBlock(), _createElementBlock("a", _hoisted_1, "New"))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tags, (tag, idx) => {
      return (_openBlock(), _createElementBlock("a", {
        key: idx,
        href: `/prints/${tag.slug}`,
        class: _normalizeClass({ featured: tag.featured })
      }, _toDisplayString(tag.title), 11, _hoisted_2))
    }), 128))
  ], 2))
}