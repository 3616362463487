<template>
  <marquee-text
    :duration="30"
    :repeat="10"
    :paused="marqueePaused"
    class="border-y py-2"
    @mouseover="marqueePaused = true"
    @mouseleave="marqueePaused = false"
  >
    <div class="flex">
      <a href="/prints/team-gb" :class="itemClass()" class="underscore-hover">King & McGaw x Team GB</a>
      <span :class="itemClass()">Handmade in Sussex</span>
      <span :class="itemClass()">Over 20,000 5-star reviews</span>
    </div>
  </marquee-text>
</template>

<script lang="ts" setup>
import MarqueeText from 'vue-marquee-text-component'
import { ref } from 'vue'
const marqueePaused = ref(false)

function itemClass() {
  return 'ml-8 md:ml-12 lg:ml-24'
}
</script>
