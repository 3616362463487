<template>
  <div>
    <ul class="ml-8 list-disc !mt-0">
      <li>
        If you're not entirely happy with your order, simply return it to us within 30 days for a replacement or full
        refund.
      </li>
      <li>Please read our <a href="/help/returns" data-fancybox data-type="ajax">full returns policy</a> here.</li>
      <li v-if="isTier1">Please note: This item is not eligible for discount.</li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useProductStore } from '../../stores/useProductStore'

const { isTier1 } = storeToRefs(useProductStore())
</script>
