import useHttps from './useHttps'

export interface Profile {
  ownerId: string
  auth_token: string | undefined
  internal: boolean | false
}

export default function useSession() {
  const { get } = useHttps()

  function loadSession() {
    const url = `${process.env.VUE_APP_API_SESSION_URL}`
    return get(url).catch((e) => {
      console.log(`use-session: load error ${e.code}: ${e.message}`)
      throw e
    }) // todo: enhance error checking
  }

  return {
    loadSession
  }
}
