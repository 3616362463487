import { createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "flex space-x-2 items-center cursor-pointer",
    title: "Basket",
    onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('clicked')))
  }, [
    _createVNode($setup["KmIcon"], {
      id: "cart-link",
      name: "bag",
      class: "align-middle"
    }),
    _createElementVNode("div", {
      class: _normalizeClass([{ 'text-white': $props.loading }, "min-w-2.5"])
    }, _toDisplayString($props.itemCount), 3)
  ]))
}