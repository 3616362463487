import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-5 gap-9 gap-y-3" }
const _hoisted_2 = { class: "col-span-2" }
const _hoisted_3 = {
  class: "col-span-3",
  title: "height x width"
}
const _hoisted_4 = {
  class: "col-span-3",
  title: "height x width"
}
const _hoisted_5 = {
  key: 0,
  class: "col-span-5 typeset-8"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString($setup.productSizeDescription()), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString($setup.productSize) + " cm", 1),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-span-2" }, "Image size", -1)),
    _createElementVNode("div", _hoisted_4, _toDisplayString($setup.imageSize) + " cm", 1),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col-span-5 typeset-8" }, "Sizes are approximate", -1)),
    (!$setup.isFramed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, " This print comes with a border containing the artist name, print title and publishing details near the bottom edge of the paper. "))
      : _createCommentVNode("", true)
  ]))
}