import useHttps from './useHttps'

// todo: make environment var and pass in??
const FRAMING_LEAD_TIME_URL = 'https://secure.kingandmcgaw.com/api/v2/framed_leadtime_days/'

export default function useProductOptions() {
  const { get, post } = useHttps()

  async function fetchForProduct(productId: string) {
    return get(`/api/product_options/${productId}`)
  }

  async function fetchFramingLeadTime() {
    const defaultLeadTime = 5

    return get(FRAMING_LEAD_TIME_URL)
      .then((response) => {
        const leadTime = parseInt(response)
        if (Number.isInteger(leadTime)) {
          return leadTime
        }
        return defaultLeadTime
      })
      .catch((_error) => {
        return defaultLeadTime
      })
  }

  async function postDefaultProduct(artwork, product) {
    const url = `/api/default-products`
    const payload = {
      artwork_id: artwork.id,
      frame_id: product.frame?.id,
      mount_id: product.mount?.id,
      size: ((artwork.is_pod && product?.description) || '').toLowerCase()
    }

    return post(url, payload).then((response) => {
      if (response.data.artwork_id === artwork.id) {
        return true
      } else {
        return false
      }
    })
  }

  return {
    fetchForProduct,
    fetchFramingLeadTime,
    postDefaultProduct
  }
}
