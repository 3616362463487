import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["MarqueeText"], {
    duration: 30,
    repeat: 10,
    paused: $setup.marqueePaused,
    class: "border-y py-2",
    onMouseover: _cache[0] || (_cache[0] = $event => ($setup.marqueePaused = true)),
    onMouseleave: _cache[1] || (_cache[1] = $event => ($setup.marqueePaused = false))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("a", {
          href: "/prints/team-gb",
          class: _normalizeClass([$setup.itemClass(), "underscore-hover"])
        }, "King & McGaw x Team GB", 2),
        _createElementVNode("span", {
          class: _normalizeClass($setup.itemClass())
        }, "Handmade in Sussex", 2),
        _createElementVNode("span", {
          class: _normalizeClass($setup.itemClass())
        }, "Over 20,000 5-star reviews", 2)
      ])
    ]),
    _: 1
  }, 8, ["paused"]))
}