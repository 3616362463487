import useHttps from './useHttps'
import type { WishList } from '../interfaces/ListInterfaces'

export default function useWishList() {
  const { get, post } = useHttps()

  function csrfToken() {
    const htmlElement = document?.querySelector('meta[name=csrf-token]') as HTMLMetaElement
    return htmlElement.content
  }

  async function addToWishList(listId: string, productId: string, productType: string) {
    const headers = { 'X-CSRF-Token': csrfToken(), 'content-type': 'application/json' }
    const body = {
      product_id: productId,
      item_type: productType,
      gallery_id: listId
    }

    return post('/gallery_item', body, { headers: headers })
      .then((res) => {
        return res.status
      })
      .catch((error) => {
        console.log('Error adding to wishlist', error)
      })
  }

  async function getWishLists(ownerId: string): Promise<WishList[]> {
    const params = {
      type: 'gallery',
      owner_id: ownerId
    }
    return get('/api/lists', { params: params }).then((res) => res)
  }

  async function addNewList(title: string, description: string) {
    const headers = { 'X-CSRF-Token': csrfToken(), 'content-type': 'application/json', Accept: 'application/json' }
    const url = '/gallery'
    const body = {
      gallery: {
        title: title,
        description: description
      }
    }
    return useHttps()
      .post(url, body, { headers: headers })
      .then((res) => res)
  }

  return {
    addToWishList,
    getWishLists,
    addNewList
  }
}
