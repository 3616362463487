<template>
  <Teleport v-if="open" to="body">
    <div id="modal-bg" class="modal-bg" @click.stop="closeModal"></div>
    <div
      id="modal-content"
      :class="modalClass"
      class="modal-content absolute bg-white py-4 px-4 md:px-8 pb-8 max-h-full overflow-y-auto"
      @keyup.esc="closeModal"
    >
      <slot name="header">
        <div class="flex justify-between items-center border-b mb-10 py-4">
          <p class="typeset-8">{{ title }}&nbsp;</p>
          <KmIcon class="h-4 w-4 cursor-pointer" name="close" @click="closeModal" />
        </div>
      </slot>
      <slot></slot>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import KmIcon from './KmIcon.vue'

const props = defineProps<{
  open: boolean
  title?: string
  fullSize?: boolean
  maxWidth?: string
}>()

const emit = defineEmits<{
  (e: 'close'): void
}>()

const modalClass = computed(() => {
  if (props.fullSize) {
    return 'inset-0'
  } else {
    const maxWidth = props.maxWidth || '4xl'
    return `inset-0 md:bottom-auto md:top-5 md:left-5 md:right-5 mx-auto lg:w-full lg:max-w-${maxWidth}`
  }
})

function closeModal() {
  emit('close')
}
</script>
