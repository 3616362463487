import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "md:grid grid-cols-12 gap-6 lg:gap-9 z-auto" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["ProductImage"], {
      src: $setup.getSrc()
    }, null, 8, ["src"]),
    _createVNode($setup["ProductDetails"])
  ]))
}