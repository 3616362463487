import { createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-pin-media", "href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("a", {
      id: "pinterest-button",
      class: "hidden",
      "data-pin-custom": true,
      "data-pin-media": $setup.getSrc(),
      href: $setup.pinterestHref(),
      onClick: _cache[0] || (_cache[0] = _withModifiers($event => ($setup.savePin()), ["prevent","stop"]))
    }, [
      _createVNode($setup["KmIcon"], {
        name: "share-p",
        class: "hover:text-error cursor-pointer -mb-1"
      })
    ], 8, _hoisted_1)
  ]))
}