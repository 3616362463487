<template>
  <div class="grid grid-cols-1 gap-8">
    <div v-for="mount in mounts" :key="mount.mount_id">
      <div class="flex">
        <img class="w-44 mr-6" :src="mount.image_url" />
        <div>
          <p class="typeset-8">{{ mount.title }}</p>
          <p class="leading-6">{{ mountDescription(mount.title) }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="flex">
    <a href="/stories/what-is-a-mount" class="button mt-5 mx-auto">Read more about mounts</a>
  </div>
</template>

<script lang="ts" setup>
import type { Mount } from '../../interfaces/ProductInterfaces'

defineProps<{
  mounts: Mount[]
  mountId?: null
}>()

function mountDescription(title: string) {
  let description: string

  switch (title.toLowerCase()) {
    case 'window mount':
      description =
        'Create a window around your print with acid free, conservation-grade mount board with bevelled edges.'
      break

    case 'recessed box':
      description = 'Enhance your print by setting it back from the glazing with a small border around the artwork.'
      break

    case 'no mount':
      description = 'Frame your print with no border.'
      break

    case 'float mount':
      description =
        'Float your artwork on conservation-grade mount board with a 20 mm border to accentuate the edges of print.'
      break
  }

  return description
}
</script>
