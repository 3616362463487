<template>
  <div class="md:grid grid-cols-12 gap-6 lg:gap-9 z-auto">
    <ProductImage :src="getSrc()" />
    <ProductDetails />
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount } from 'vue'
import { storeToRefs } from 'pinia'
import { useProductStore } from './stores/useProductStore'
import ProductDetails from './components/products/ProductDetails.vue'
import ProductImage from './components/products/ProductImage.vue'
import useGA4 from '../../utils/useGA4'

const productStore = useProductStore()
const props = defineProps<{ htmlSnippets: string }>()
const { activeProduct } = storeToRefs(productStore)
const { trackEventGA4 } = useGA4()

async function setArtwork() {
  const artwork = window.appOptions.artwork
  const hash = window.location.hash
  const selectedProductId = hash.length > 0 ? hash.slice(1) : undefined

  productStore.setArtwork(artwork, selectedProductId).then((_) => {
    trackEventGA4('view_item', artwork)
  })

  productStore.setHtmlSnippets(props.htmlSnippets)
}

async function setPodMedia() {
  const podMedia = window.appOptions.podMedia
  productStore.setPodMedia(podMedia)
}

function getSrc(): string {
  return activeProduct.value ? activeProduct.value.image_url : ''
}

onBeforeMount(async () => {
  await setArtwork()
  await setPodMedia()
})
</script>
