import { defineStore } from 'pinia'
import type { Profile } from '../hooks/useSession'
import useSession from '../hooks/useSession'
import type { WishList } from '../interfaces/ListInterfaces'
import useWishList from '../hooks/useWishList'
import useHttps from '../hooks/useHttps'
import type { KmSelectBoxOption } from '../interfaces/ComponentInterfaces'

export interface SessionStoreState {
  error: any
  profile: Profile
  sessionLoaded: boolean
  loading: boolean
  loginModal: boolean
  wishListModal: boolean
  wishlistProductId: string
  wishlistProductType: string
  wishLists: KmSelectBoxOption[]
  productId: string
}

// todo: localeInfo
/**
 * useSessionStore - Pinia based store which fetches and holds session
 * information.
 *
 * Use the following functions to get session information -
 * `getOwnerId` and `getIsLoggedIn` - Other functions should be private
 *
 * callSession should only be used in testing to mock the session response
 *
 * todo: find a way to make Pinea actions private
 */
export const useSessionStore = defineStore('session', {
  state: () =>
    ({
      error: {},
      profile: {} as Profile,
      sessionLoaded: false,
      loading: false,
      loginModal: false,
      wishListModal: false,
      wishLists: [],
      wishlistProductId: '',
      wishlistProductType: '',
      productId: ''
    }) as SessionStoreState,

  actions: {
    /**
     * Added to allow spying (for testing)
     * and changing the return function (ie: not make external call
     * @param sessionFn
     */
    async callSession(sessionFn: any): Promise<void> {
      return sessionFn()
    },

    /**
     * This should be another *hidden* or private method, but it is called from
     * two external actions so cannot be contained within one such as the waitLoad
     * method
     * @param forceReload
     */
    async pullSessionIntoStore(forceReload?: boolean): Promise<void> {
      if (this.loading) {
        const waitLoad = async (): Promise<void> => {
          return new Promise<void>((resolve) => {
            if (!this.loading) {
              resolve()
            } else {
              setTimeout(() => {
                waitLoad().then(() => {
                  resolve()
                })
              }, 100)
            }
          })
        }
        await waitLoad()
      }
      if (this.sessionLoaded && !forceReload) return
      this.loading = true
      this.sessionLoaded = false
      const { loadSession } = useSession()
      return this.callSession(loadSession)
        .then((session: any) => {
          this.profile = {
            ownerId: session.owner_id,
            auth_token: session.auth_token,
            internal: session.internal
          }
          this.sessionLoaded = true
        })
        .catch((error) => {
          console.log('useSessionStore: caught error loading session', error)
          this.sessionLoaded = false
        })
        .finally(() => {
          this.loading = false
        })
    },

    async getOwnerId(): Promise<string | null> {
      await this.pullSessionIntoStore()
      return this.profile.ownerId ? this.profile.ownerId : null
    },

    async getIsLoggedIn(reloadSession?: boolean): Promise<boolean> {
      try {
        await this.pullSessionIntoStore(reloadSession)
        return this.profile.auth_token != undefined
      } catch (error) {
        console.log('Error getting logged in', error)
        return false
      }
    },

    async getLoginModal(artworkId?: string) {
      if (artworkId) {
        this.setProductId(artworkId)
      }
      this.getIsLoggedIn(true).then((res) => {
        if (res) {
          this.loginModal = false
          this.wishListModal = true
        } else {
          this.loginModal = true
          this.wishListModal = false
        }
      })
    },

    async fetchWishLists() {
      const { getWishLists } = useWishList()
      const data = await getWishLists(this.profile.ownerId)

      this.wishLists = data.map((list: WishList) => {
        return {
          id: list.id,
          description: `${list.title} (${list.num_items} items)`,
          selected: false,
          value: list.id
        }
      })
    },

    async login(data: any, headers: any): Promise<string> {
      return useHttps()
        .post('/login', data, { headers: headers })
        .then((res) => {
          console.log(res.request.responseURL)
          return res.request.responseURL
        })
        .catch((error) => {
          console.log('Login error: ', error)
        })
    },

    setProductId(artworkId: string) {
      this.productId = artworkId
    }
  }
})
