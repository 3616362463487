import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-auto text-center -mb-10" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "flex justify-end m-5" }
const _hoisted_4 = { class: "w-[112px] md:w-[170px] text-center" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: $props.src,
        style: _normalizeStyle({ width: `${$setup.widthPx}px` }),
        alt: "loading",
        class: "mx-auto inline-block align-middle cursor-pointer",
        onClick: _cache[0] || (_cache[0] = $event => ($setup.productStore.toggleViewInARoom()))
      }, null, 12, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          id: "chair",
          innerHTML: $setup.chair
        }, null, 8, _hoisted_5),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "border-l border-r h-4 flex flex-col divide-y" }, [
          _createElementVNode("div", { class: "h-1/2" }),
          _createElementVNode("div", { class: "h-1/2" })
        ], -1)),
        _cache[2] || (_cache[2] = _createTextVNode(" 50cm "))
      ])
    ])
  ], 64))
}