import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "modal-bg p-2" }
const _hoisted_3 = { class: "bg-white w-full md:w-1/3 p-4 mx-auto" }
const _hoisted_4 = { class: "flex flex-col" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.getIsLoggedIn)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "button button-primary",
            onClick: $setup.showCreateModal
          }, "Create wish list")
        ]))
      : _createCommentVNode("", true),
    _createVNode($setup["KmModal"], {
      open: $setup.show,
      onClose: $setup.closeModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex justify-between" }, [
              _createElementVNode("h3", null, "Create Wish List")
            ], -1)),
            _createElementVNode("div", _hoisted_4, [
              _cache[2] || (_cache[2] = _createElementVNode("h6", { class: "mt-4" }, "Title of wish list", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.title) = $event)),
                class: "",
                type: "text"
              }, null, 512), [
                [_vModelText, $setup.title]
              ]),
              _cache[3] || (_cache[3] = _createElementVNode("h6", { class: "mt-4" }, "Description", -1)),
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.description) = $event)),
                class: "",
                rows: "10"
              }, null, 512), [
                [_vModelText, $setup.description]
              ]),
              _createElementVNode("div", { class: "flex flex-row justify-between my-4" }, [
                _createElementVNode("button", {
                  class: "button w-1/2 mr-2",
                  onClick: $setup.closeModal
                }, "cancel"),
                _createElementVNode("button", {
                  class: "button bg-black text-white w-1/2 ml-2",
                  onClick: $setup.addNewList
                }, "Add")
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["open"])
  ], 64))
}