<template>
  <div>
    <h3 v-if="heading" class="pb-8">{{ heading }}</h3>

    <div class="text-format mb-4">
      <div class="divide-y border-t border-b">
        <div v-for="(panel, idx) in panels" :key="idx" class="py-4">
          <div
            v-show="openedIndex !== idx"
            :id="`accordion-${kebabCase(panel.heading)}-open`"
            class="flex justify-between items-baseline cursor-pointer"
            @click="openedIndex = idx"
          >
            <div>{{ panel.heading }}</div>
            <KmIcon name="close" class="rotate-45 h-3" />
          </div>
          <div
            v-show="openedIndex === idx"
            :id="`accordion-${kebabCase(panel.heading)}-close`"
            class="flex justify-between items-baseline cursor-pointer pb-6"
            @click="openedIndex = -1"
          >
            <div class="typeset-4 pr-4">{{ panel.heading }}</div>
            <KmIcon name="close" class="h-3" />
          </div>
          <div v-show="openedIndex === idx" class="pb-4">
            <component :is="panel.content"></component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { kebabCase } from 'lodash'
import KmIcon from './KmIcon.vue'
import type { KmAccordionPanel } from '../../interfaces/ComponentInterfaces'

const props = defineProps<{
  heading?: string
  panels: KmAccordionPanel[]
  defaultOpen?: string
}>()

const openedIndex = ref(parseInt(props.defaultOpen) || -1)
</script>
