<template>
  <KmModal :open="wishListModal" max-width="lg" @close="closeWishListModal">
    <template #header>
      <div class="flex justify-between border-b">
        <h2 class="my-5">Add to wish list</h2>
        <KmIcon class="mt-4 h-4 w-4 cursor-pointer" name="close" @click="closeWishListModal" />
      </div>
    </template>

    <KmWishList :product-id="productId"></KmWishList>
  </KmModal>

  <KmModal :open="loginModal" max-width="lg" @close="closeLoginModal">
    <template #header>
      <div class="flex justify-between border-b mb-5">
        <h2 class="my-5">Login</h2>
        <KmIcon class="mt-4 h-4 w-4 cursor-pointer" name="close" @click="closeLoginModal" />
      </div>
    </template>

    <KmLogin></KmLogin>
  </KmModal>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useSessionStore } from './stores/useSessionStore'
import KmIcon from './components/general/KmIcon.vue'
import KmLogin from './components/general/KmLogin.vue'
import KmModal from './components/general/KmModal.vue'
import KmWishList from './components/general/KmWishList.vue'

const productId = ref('')
const { loginModal, wishListModal } = storeToRefs(useSessionStore())

function closeWishListModal() {
  wishListModal.value = false
}

function closeLoginModal() {
  loginModal.value = false
}
</script>
