import Swiper from 'swiper'
import { Navigation, Thumbs } from 'swiper/modules'
import { merge } from 'lodash-es'

export default function setupSwiper(ele: HTMLElement) {
  // https://swiperjs.com/swiper-api
  let swiperConfig = {
    modules: [Navigation, Thumbs],
    roundLengths: true,
    grabCursor: true,
    slidesPerGroup: 1,
    slidesPerView: 1.3,
    spaceBetween: 18,
    navigation: {
      nextEl: ele.getElementsByClassName('swiper-button-next')[0],
      prevEl: ele.getElementsByClassName('swiper-button-prev')[0]
    },
    breakpoints: {
      389: {
        slidesPerGroup: 1,
        slidesPerView: 1.3,
        spaceBetween: 18
      },
      767: {
        slidesPerGroup: 3,
        slidesPerView: 2.5,
        spaceBetween: 24
      },
      1023: {
        slidesPerGroup: 3,
        slidesPerView: 'auto',
        spaceBetween: 36
      }
    }
  }

  // Config overrides
  // <div class="swiper" data-options='{"slidesPerView":3}'>
  if (ele.dataset.options) {
    const options = JSON.parse(ele.dataset.options)
    swiperConfig = merge(swiperConfig, options)
  }

  if (ele.dataset.thumbs) {
    const thumbSwiper = setupSwiper(document.querySelector(ele.dataset.thumbs))
    swiperConfig = {
      ...swiperConfig,
      thumbs: {
        swiper: thumbSwiper
      }
    }
  }

  return new Swiper(ele, swiperConfig)
}
