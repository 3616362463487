import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "px-2 opacity-20 cursor-default"
}
const _hoisted_2 = ["value"]
const _hoisted_3 = {
  key: 3,
  class: "px-2 opacity-20 cursor-default"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.enableDecrease)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "px-2",
          "data-test": "cart-quantity-decrease",
          onClick: _cache[0] || (_cache[0] = $event => ($setup.decrease()))
        }, [
          _createVNode($setup["KmIcon"], {
            name: "minus",
            class: "h-3 w-3"
          })
        ]))
      : (_openBlock(), _createElementBlock("button", _hoisted_1, [
          _createVNode($setup["KmIcon"], {
            name: "minus",
            class: "h-3 w-3"
          })
        ])),
    _createElementVNode("input", {
      class: _normalizeClass(["!w-16 !h-8 text-center", $setup.inputClass]),
      inputmode: "numeric",
      name: "quantity",
      pattern: "[0-9]*",
      type: "text",
      value: $setup.itemQuantity,
      onChange: $setup.submitQuantity,
      onFocus: _cache[1] || (_cache[1] = $event => ($event.target.select())),
      onKeydown: $setup.submitOnEnter,
      onModel: _cache[2] || (_cache[2] = (...args) => ($setup.itemQuantity && $setup.itemQuantity(...args)))
    }, null, 42, _hoisted_2),
    ($setup.enableIncrease)
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          class: "px-2",
          "data-test": "cart-quantity-increase",
          onClick: _cache[3] || (_cache[3] = $event => ($setup.increase()))
        }, [
          _createVNode($setup["KmIcon"], {
            name: "close",
            class: "rotate-45 h-3 w-3"
          })
        ]))
      : (_openBlock(), _createElementBlock("button", _hoisted_3, [
          _createVNode($setup["KmIcon"], {
            name: "close",
            class: "rotate-45 h-3 w-3"
          })
        ]))
  ], 64))
}