<template>
  <div class="h-full w-full pt-4">
    <div class="overflow-y-scroll h-full">
      <img :class="imageClass" :src="src" alt="loading" />
    </div>

    <div class="flex-col absolute right-5 bottom-10">
      <div :class="{ 'opacity-30': zoomed }" class="swiper-button" @click="zoomIn">
        <KmIcon name="close" class="rotate-45"></KmIcon>
      </div>

      <div :class="{ 'opacity-30': !zoomed }" class="swiper-button" @click="zoomOut">
        <KmIcon name="minus"></KmIcon>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import KmIcon from './KmIcon.vue'

const IMAGE_CLASS_DEFAULT = 'mx-auto align-middle max-w-7xl'
const IMAGE_CLASS_ZOOMED = 'pt-4 px-16 m-0 w-full'

defineProps<{ src: string }>()
const zoomed = ref(false)

const imageClass = ref(IMAGE_CLASS_DEFAULT)

function zoomIn() {
  imageClass.value = IMAGE_CLASS_ZOOMED
  zoomed.value = true
}

function zoomOut() {
  imageClass.value = IMAGE_CLASS_DEFAULT
  zoomed.value = false
}
</script>
