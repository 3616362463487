<template>
  <div class="relative deep-inline">
    <div ref="content" class="overflow-y-hidden line-clamp-4">
      <slot></slot>
    </div>

    <template v-if="enabled">
      <a class="mt-2 underscore cursor-pointer block" @click="toggle">{{ linkText() }}</a>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'

const content = ref(null)
const expanded = ref(true)
const enabled = ref(false)

onMounted(() => {
  if (content.value.scrollHeight > content.value.clientHeight) {
    enabled.value = true
    expanded.value = false
  }
})

function toggle() {
  expanded.value = !expanded.value
  contentClass()
}

// NB: we can't use Vue's class binding here because in order for this component to work
// it needs to be rendered with the line-clamp class before mounting.
function contentClass() {
  const klass = expanded.value ? '' : 'line-clamp-4'
  content.value.classList = [klass]
}

function linkText() {
  return expanded.value ? '- read less' : '+ read more'
}
</script>
