<template>
  <div class="flex justify-between">
    <button class="button flex items-center" @click="change">
      <span class="mr-2">See next</span>
      <KmIcon class="refresh" name="refresh"></KmIcon>
    </button>

    <button class="text-white button bg-black flex items-center" @click="addToCart">Add all to basket</button>
  </div>

  <div v-if="dataSet" id="img" class="pt-10 md:px-10 lg:px-40 fade-in-image">
    <div
      v-for="(subSet, outerIndex) in splitDataSet()"
      :key="outerIndex"
      :class="[conditionalClass(outerIndex), conditionalWidths(outerIndex)]"
    >
      <div v-for="(item, innerIndex) in subSet" :key="innerIndex" :style="widthHeight(item)" class="m-2">
        <a :href="item.url">
          <div v-if="item.image_url"><img :src="item.image_url" /></div>
        </a>
        <div class="tags my-2">
          <a :href="item.url">{{ item.title }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref, watch } from 'vue'
import KmIcon from './components/general/KmIcon.vue'
import type { ItemSet } from './apps/main/src/interfaces/ComponentInterfaces'

const itemSets = ref([])
const dataSet = ref()
let counter = 0
let cssCounter = 0

function addToCart() {
  const cartUrl = `gallery/${dataSet.value.id}/add_to_cart`
  window.location.href = cartUrl
}
function widthHeight(item: any) {
  return {
    width: item.width_pct + '%',
    height: item.height_pct + '%'
  }
}

function conditionalClass(index: number) {
  if (index === 0) {
    return 'md:flex items-end'
  } else {
    return 'md:flex items-start'
  }
}

function conditionalWidths(outerIndex: number) {
  if (cssCounter === 0 && outerIndex === 0) {
    return 'sm:w-3/4 m-auto'
  } else if (cssCounter === 1 && outerIndex === 1) {
    return 'sm:w-3/4 m-auto'
  }
}

onBeforeMount(() => {
  itemSets.value = window.inspiration_item_sets<ItemSet[]>
})

watch(itemSets, () => {
  changeDataSet()
})

function change() {
  const element = document.getElementById('img')
  element.addEventListener('animationstart', () => {
    return
  })
  changeDataSet()
  changeCss()
  reAnimate()
}

function changeDataSet() {
  if (counter >= itemSets.value.length) {
    counter = 0
  }
  dataSet.value = itemSets.value[counter]
  counter++
}

function changeCss() {
  if (cssCounter >= 1) {
    cssCounter = 0
  } else {
    cssCounter = 1
  }
}

function reAnimate() {
  const element = document.getElementById('img')

  element.classList.remove('fade-in-image') // reset animation
  void element.offsetWidth // trigger reflow
  element.classList.add('fade-in-image')
}

function splitDataSet() {
  const midIndex = parseInt(dataSet.value.items.length / 2)
  if (cssCounter == 0) {
    return [dataSet.value.items.slice(0, midIndex), dataSet.value.items.slice(midIndex)]
  } else {
    return [dataSet.value.items.slice(0, midIndex + 1), dataSet.value.items.slice(midIndex + 1)]
  }
}
</script>
