import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "form-container" }
const _hoisted_2 = {
  key: 0,
  class: "flash-notice p3 text-center"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("form", _hoisted_1, [
      ($setup.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Something went wrong please try again"))
        : _createCommentVNode("", true),
      _cache[2] || (_cache[2] = _createElementVNode("label", null, "Email", -1)),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.email) = $event)),
        autocomplete: "username",
        placeholder: "Email",
        type: "email"
      }, null, 512), [
        [_vModelText, $setup.email]
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("label", null, "Password", -1)),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.password) = $event)),
        autocomplete: "current-password",
        placeholder: "Password",
        type: "password"
      }, null, 512), [
        [_vModelText, $setup.password]
      ]),
      _createElementVNode("button", {
        class: "button button-primary w-full mt-4",
        onClick: _withModifiers($setup.login, ["prevent"])
      }, "Login")
    ]),
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex mt-4 justify-between" }, [
      _createElementVNode("a", { href: "/register" }, "Create account"),
      _createElementVNode("a", { href: "/password" }, "Forgot password")
    ], -1))
  ], 64))
}