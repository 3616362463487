<template>
  <div class="flex items-center justify-between">
    <a href="/recently-viewed" title="Recently Viewed Items" class="flex items-center">
      <KmIcon id="recently-viewed-link" name="eye" class="align-middle mr-1 pt-1" />
      <div class="min-w-2.5">
        {{ numberOfRecentlyViewedItems }}
      </div>
    </a>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useHistoryStore } from '../../stores/useHistoryStore'
import KmIcon from '../../components/general/KmIcon.vue'

const { list } = storeToRefs(useHistoryStore())

const numberOfRecentlyViewedItems = computed(() => {
  return list.value?.num_items
})
</script>
