import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 gap-8" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "typeset-8" }
const _hoisted_5 = { class: "leading-6" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.mounts, (mount) => {
        return (_openBlock(), _createElementBlock("div", {
          key: mount.mount_id
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("img", {
              class: "w-44 mr-6",
              src: mount.image_url
            }, null, 8, _hoisted_3),
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_4, _toDisplayString(mount.title), 1),
              _createElementVNode("p", _hoisted_5, _toDisplayString($setup.mountDescription(mount.title)), 1)
            ])
          ])
        ]))
      }), 128))
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex" }, [
      _createElementVNode("a", {
        href: "/stories/what-is-a-mount",
        class: "button mt-5 mx-auto"
      }, "Read more about mounts")
    ], -1))
  ], 64))
}