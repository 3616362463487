import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ml-8 list-disc !mt-0" }
const _hoisted_2 = { key: 0 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("ul", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("li", null, " If you're not entirely happy with your order, simply return it to us within 30 days for a replacement or full refund. ", -1)),
      _cache[1] || (_cache[1] = _createElementVNode("li", null, [
        _createTextVNode("Please read our "),
        _createElementVNode("a", {
          href: "/help/returns",
          "data-fancybox": "",
          "data-type": "ajax"
        }, "full returns policy"),
        _createTextVNode(" here.")
      ], -1)),
      ($setup.isTier1)
        ? (_openBlock(), _createElementBlock("li", _hoisted_2, "Please note: This item is not eligible for discount."))
        : _createCommentVNode("", true)
    ])
  ]))
}