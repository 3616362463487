<template>
  <div class="tags" :class="{ 'clamp-tags': clamp }">
    <a v-if="isArtworkNew()" href="/prints/new">New</a>
    <a v-for="(tag, idx) in tags" :key="idx" :href="`/prints/${tag.slug}`" :class="{ featured: tag.featured }">
      {{ tag.title }}
    </a>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  createdAt: string
  clamp?: boolean
  tags: {
    featured: string
    slug: string
    title: string
  }[]
}>()

function isArtworkNew() {
  const createdAt = new Date(props.createdAt).getTime()
  const diff = Date.now() - createdAt

  // 60 seconds * 60 minutes * 24 hours * 30 days * 3 months * microsecond multiplier
  const threeMonths = 60 * 60 * 24 * 30 * 3 * 1000
  return diff < threeMonths
}
</script>
