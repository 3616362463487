import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = {
  href: "/recently-viewed",
  title: "Recently Viewed Items",
  class: "flex items-center"
}
const _hoisted_3 = { class: "min-w-2.5" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", _hoisted_2, [
      _createVNode($setup["KmIcon"], {
        id: "recently-viewed-link",
        name: "eye",
        class: "align-middle mr-1 pt-1"
      }),
      _createElementVNode("div", _hoisted_3, _toDisplayString($setup.numberOfRecentlyViewedItems), 1)
    ])
  ]))
}