import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full border-t"
}
const _hoisted_2 = { class: "flex justify-between border-b py-4" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "pl-2" }
const _hoisted_6 = { class: "border-b py-4" }
const _hoisted_7 = { class: "flex justify-between" }
const _hoisted_8 = {
  key: 0,
  class: "my-3"
}
const _hoisted_9 = { class: "flex" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["src", "alt", "title"]
const _hoisted_12 = {
  key: 1,
  class: "button button-grey cursor-pointer !h-[36px]",
  "data-test": "unframed-option"
}
const _hoisted_13 = { class: "flex justify-between border-b py-4 mb-5" }
const _hoisted_14 = { class: "flex" }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "pl-2" }
const _hoisted_17 = {
  key: 0,
  class: "button button-primary w-full"
}
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.activeOptions)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[4] || (_cache[4] = _createElementVNode("span", null, "Size:", -1)),
            ($setup.showSelect.sizes)
              ? (_openBlock(), _createBlock($setup["KmSelectBox"], {
                  key: 0,
                  options: $setup.sizeKmOptions(),
                  onOptionUpdate: $setup.selectUpdated
                }, null, 8, ["options"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, _toDisplayString($setup.sizeCategoryDescription()), 1)
                ]))
          ]),
          (!$setup.getViewInARoom)
            ? (_openBlock(), _createBlock($setup["KmIcon"], {
                key: 0,
                name: "expand",
                class: "h-6 w-6 cursor-pointer",
                onClick: _cache[0] || (_cache[0] = $event => ($setup.toggleViewInARoom()))
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", null, "Frame: " + _toDisplayString($setup.selectedTitle($props.activeProduct.frame, 'No frame')), 1),
            _createVNode($setup["KmIcon"], {
              name: "help",
              class: "h-6 w-6 cursor-pointer",
              onClick: _cache[1] || (_cache[1] = $event => ($setup.toggleFrameModal()))
            })
          ]),
          ($setup.showSelect.frames)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.activeOptions.frames, (f) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: f.frame_id,
                      class: _normalizeClass([{ '!border-black': $setup.isFrameSelected(f), 'border-white': !$setup.isFrameSelected(f) }, "frame-icon"]),
                      onClick: $event => (_ctx.$emit('updateOptions', f.product_id))
                    }, [
                      (f.image_url)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: f.image_url,
                            alt: f.title,
                            title: f.title
                          }, null, 8, _hoisted_11))
                        : (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[5] || (_cache[5] = [
                            _createElementVNode("span", { class: "hidden sm:inline-block whitespace-nowrap" }, "No frame", -1),
                            _createElementVNode("span", { class: "inline-block sm:hidden" }, "None", -1)
                          ])))
                    ], 10, _hoisted_10))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _cache[6] || (_cache[6] = _createElementVNode("span", null, "Mount:", -1)),
            ($setup.showSelect.mounts)
              ? (_openBlock(), _createBlock($setup["KmSelectBox"], {
                  key: 0,
                  options: $setup.mountKmOptions(),
                  onOptionUpdate: $setup.selectUpdated
                }, null, 8, ["options"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("span", _hoisted_16, _toDisplayString($setup.describeFromMount($props.activeProduct.mount)), 1)
                ]))
          ]),
          ($setup.showMountHelp)
            ? (_openBlock(), _createBlock($setup["KmIcon"], {
                key: 0,
                name: "help",
                class: "h-6 w-6 cursor-pointer",
                onClick: _cache[2] || (_cache[2] = $event => ($setup.toggleMountModal()))
              }))
            : _createCommentVNode("", true)
        ]),
        ($setup.adding)
          ? (_openBlock(), _createElementBlock("button", _hoisted_17, [
              _createVNode($setup["KmIcon"], {
                name: "spinner",
                class: "h-4 w-4 mr-2 animate-spin"
              }),
              _cache[7] || (_cache[7] = _createTextVNode(" Adding… "))
            ]))
          : (_openBlock(), _createElementBlock("button", {
              key: 1,
              id: "add-to-cart-button",
              class: "button button-primary w-full button-sticky-mobile",
              "data-test": "cart-button",
              onClick: _cache[3] || (_cache[3] = $event => (_ctx.$emit('addToCart', $props.activeProduct.id)))
            }, [
              ($setup.added)
                ? (_openBlock(), _createElementBlock("span", _hoisted_18, [
                    _createVNode($setup["KmIcon"], {
                      name: "tick",
                      class: "h-4 w-4 pt-1 mr-2"
                    }),
                    _cache[8] || (_cache[8] = _createTextVNode(" Added to basket"))
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString($setup.addToCartButtonText), 1))
            ])),
        _createVNode($setup["KmModal"], {
          open: $setup.frameModalState,
          onClose: $setup.toggleFrameModal
        }, {
          default: _withCtx(() => [
            _createVNode($setup["KmFrameHelp"])
          ]),
          _: 1
        }, 8, ["open"]),
        _createVNode($setup["KmModal"], {
          open: $setup.mountModalState,
          onClose: $setup.toggleMountModal
        }, {
          default: _withCtx(() => [
            _createVNode($setup["KmMountHelp"], {
              mounts: $props.activeOptions.mounts
            }, null, 8, ["mounts"])
          ]),
          _: 1
        }, 8, ["open"])
      ]))
    : _createCommentVNode("", true)
}