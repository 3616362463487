import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex pb-3 mb-5 border-b items-center justify-between" }
const _hoisted_2 = {
  key: 0,
  class: "bg-red text-white mb-5 p-2"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "grid grid-cols-12 md:gap-x-8 gap-x-4 md:gap-y-8 gap-y-4 mb-8" }
const _hoisted_5 = { class: "w-full py-4 border-t flex justify-between" }
const _hoisted_6 = { key: 2 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'minicart-visible': $setup.miniCartVisible, hidden: !$setup.miniCartVisible }, "minicart-container"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("a", {
        href: "/cart",
        class: "typeset-8"
      }, "Basket", -1)),
      _createVNode($setup["KmCartStatus"], {
        "item-count": $setup.numberOfCartItems,
        loading: $setup.loading,
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.$emit('closeCart')), ["prevent"]))
      }, null, 8, ["item-count", "loading"])
    ]),
    ($setup.cartError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.cartError), 1))
      : _createCommentVNode("", true),
    ($setup.hasItems)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, (item, idx) => {
              return (_openBlock(), _createBlock($setup["KmCartItem"], {
                key: `cart-item${idx}`,
                item: item,
                onDeleteItem: $setup.handleDeleteItem
              }, null, 8, ["item"]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[3] || (_cache[3] = _createElementVNode("div", null, "Subtotal", -1)),
            _createElementVNode("div", null, "£" + _toDisplayString($setup.goodsTotalDisplay), 1)
          ]),
          _cache[4] || (_cache[4] = _createElementVNode("a", {
            href: "/cart",
            class: "button button-primary w-full",
            "data-test": "minicart-checkout-button"
          }, "Basket", -1))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _cache[5] || (_cache[5] = _createElementVNode("p", null, "Your basket is empty", -1)),
          _createElementVNode("button", {
            class: "button button-primary w-full",
            "data-test": "minicart-back-to-shop-button",
            onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.$emit('closeCart')), ["prevent"]))
          }, " Back to shop ")
        ]))
  ], 2))
}