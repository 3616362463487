import { createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between border-b" }
const _hoisted_2 = { class: "flex justify-between border-b mb-5" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["KmModal"], {
      open: $setup.wishListModal,
      "max-width": "lg",
      onClose: $setup.closeWishListModal
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "my-5" }, "Add to wish list", -1)),
          _createVNode($setup["KmIcon"], {
            class: "mt-4 h-4 w-4 cursor-pointer",
            name: "close",
            onClick: $setup.closeWishListModal
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode($setup["KmWishList"], { "product-id": $setup.productId }, null, 8, ["product-id"])
      ]),
      _: 1
    }, 8, ["open"]),
    _createVNode($setup["KmModal"], {
      open: $setup.loginModal,
      "max-width": "lg",
      onClose: $setup.closeLoginModal
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "my-5" }, "Login", -1)),
          _createVNode($setup["KmIcon"], {
            class: "mt-4 h-4 w-4 cursor-pointer",
            name: "close",
            onClick: $setup.closeLoginModal
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode($setup["KmLogin"])
      ]),
      _: 1
    }, 8, ["open"])
  ], 64))
}