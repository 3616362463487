<template>
  <div v-if="showRecentlyViewed" ref="vueSwiper" class="swiper lg:-mr-8" :data-options="swiperConfig()">
    <div class="flex justify-between mb-8 items-center lg:pr-8">
      <h2 class="mb-4"><a href="/recently-viewed">Recently viewed</a></h2>
      <div class="swiper-buttons">
        <div class="swiper-button-prev"><KmIcon name="angle" class="rotate-180" /></div>
        <div class="swiper-button-next"><KmIcon name="angle" /></div>
      </div>
    </div>

    <div class="swiper-wrapper">
      <div
        v-for="item in recentItems"
        :key="item.id"
        :class="`swiper-slide col-width-${item.col_width}`"
        data-test-history-item
      >
        <div class="artwork-card">
          <a :href="`/prints/${item.artwork.id}#${item.item_id}`" class="artwork-card-img">
            <img :src="item.image_url" :alt="item.title" />
          </a>

          <div class="artwork-card-info mt-5 md:mt-9">
            <div class="flex justify-between gap-x-2">
              <a class="truncate" :href="`/prints/${item.artwork.id}#${item.item_id}`"> {{ item.title }}</a>
              <WishListButton :product-id="item.item_id"></WishListButton>
            </div>
          </div>

          <div data-test="artwork-price" class="mb-4">£{{ displayPrice(item.price) }}</div>

          <KmTags :tags="item.artwork.tags" :created-at="item.artwork.created_at" :clamp="true"></KmTags>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { clampTags } from '../../utils/tags'
import { computed, onBeforeMount, onUpdated, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useHistoryStore } from './stores/useHistoryStore'
import { useProductStore } from './stores/useProductStore'
import KmIcon from './components/general/KmIcon.vue'
import KmTags from './components/general/KmTags.vue'
import setupSwiper from '../../utils/swiper'
import usePrices from './hooks/usePrices'
import useSwiper from './hooks/useSwiper'
import WishListButton from './WishListButton.vue'

const { activeProduct } = storeToRefs(useProductStore())
const { displayPrice } = usePrices()
const { fetchRecentlyViewed, updateRecentlyViewed } = useHistoryStore()
const { gridColumnsByShape, swiperConfig } = useSwiper()
const { list } = storeToRefs(useHistoryStore())
const showRecentlyViewed = ref(false)
const vueSwiper = ref(null)

onUpdated(() => {
  clampTags()
})

watch(list, () => {
  showRecentlyViewed.value = list.value?.items?.length > 0 || false
})

const recentItems = computed(() => {
  const items = list.value.items.filter((item) => item.valid)

  return items.map((item) => {
    item.image_url = item.image_url.replace('img.kingandmcgaw.com', 'v1.imgix.net')
    item.image_url = `${item.image_url}?auto=compress%2Cformat&nr=20&nrs=20&q=30&w=500`
    item.col_width = gridColumnsByShape(item.artwork.shape)
    return item
  })
})

onBeforeMount(async () => {
  fetchRecentlyViewed().then((_res) => {
    if (activeProduct?.value?.id) {
      updateRecentlyViewed(activeProduct.value.id)
    }
  })
})

onUpdated(() => {
  setupSwiper(vueSwiper.value)
})
</script>
