import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-options"]
const _hoisted_2 = { class: "swiper-wrapper mb-5" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "z-10 bg-white flex flex-col gap-y-1 items-center justify-center w-14 h-14 md:w-20 md:h-20" }
const _hoisted_5 = ["href"]
const _hoisted_6 = ["src", "alt", "title"]
const _hoisted_7 = { class: "flex justify-between items-center" }
const _hoisted_8 = { class: "swiper-buttons gap-x-2" }
const _hoisted_9 = { class: "swiper-button-prev" }
const _hoisted_10 = { class: "swiper-button-next" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.galleryItems.length > 0)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref: "vueSwiper",
        class: "swiper mb-8",
        "data-options": $setup.swiperOptions()
      }, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.galleryItems, (item, idx) => {
            return (_openBlock(), _createElementBlock("div", {
              key: idx,
              class: "swiper-slide self-center"
            }, [
              (item.type == 'video')
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    "data-fancybox": "gallery",
                    "data-type": "iframe",
                    href: item.url
                  }, [
                    _createElementVNode("div", {
                      style: _normalizeStyle(item.style),
                      class: "aspect-5/3 w-full h-full flex items-center justify-center px-1"
                    }, [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode($setup["KmIcon"], {
                          class: "h-4 w-4",
                          name: "play"
                        }),
                        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "typeset-8 md:block hidden" }, "Play", -1))
                      ])
                    ], 4)
                  ], 8, _hoisted_3))
                : _createCommentVNode("", true),
              (item.type == 'image')
                ? (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    href: item.src,
                    "data-fancybox": ""
                  }, [
                    _createElementVNode("img", {
                      src: item.src,
                      alt: item.caption,
                      title: item.caption
                    }, null, 8, _hoisted_6)
                  ], 8, _hoisted_5))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode($setup["KmIcon"], {
                name: "angle",
                class: "rotate-180"
              })
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode($setup["KmIcon"], { name: "angle" })
            ])
          ])
        ])
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}