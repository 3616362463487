export default function useSwiper(): {
  swiperConfig: () => string
  gridColumnsByShape: (shape: string) => number
} {
  function swiperConfig(): string {
    const opts = {
      slidesPerGroup: 1,
      slidesPerView: 1.3,
      breakpoints: {
        '389': {
          slidesPerGroup: 1,
          slidesPerView: 1.3,
          spaceBetween: 18
        },
        '767': {
          slidesPerGroup: 3,
          slidesPerView: 2.5,
          spaceBetween: 24
        },
        '1023': {
          slidesPerGroup: 3,
          slidesPerView: 'auto',
          spaceBetween: 36
        }
      }
    }

    return JSON.stringify(opts)
  }

  function gridColumnsByShape(shape: string): number {
    let cols = 3

    switch (shape) {
      case 'tall':
        cols = 2
        break
      case 'portrait':
        cols = 3
        break
      case 'square':
        cols = 4
        break
      case 'landscape':
        cols = 4
        break
      case 'panoramic':
        cols = 5
        break
    }

    return cols
  }

  return {
    swiperConfig,
    gridColumnsByShape
  }
}
