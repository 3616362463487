<template>
  <div>
    <ul class="ml-8 list-disc !mt-0">
      <li v-if="leadTime == 1" class="hidden">Ships in 24 hours</li>
      <li v-else>Ships in {{ leadTime }} working days or less. Check your estimated delivery date during checkout.</li>
      <li>Framed orders £8.95 for standard UK shipping</li>
      <li>Unframed orders £3.95 for standard UK shipping</li>
      <li>Express delivery available for unframed items</li>
      <li>International orders are priced within the checkout</li>
      <li>
        All of our products are shipped from the UK.
        <a href="/help/shipping" data-fancybox data-type="ajax">More about shipping</a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
// import { computed, onMounted, ref, watch } from 'vue'
import { computed } from 'vue'
import { get } from 'lodash-es'
// import { getXmasShippingAvailability } from '../../hooks/useXmasAvailability'
import { storeToRefs } from 'pinia'
import { useProductStore } from '../../stores/useProductStore'

const { artwork, framingLeadTime, isFramed, isPod, isSkuProduct, productStockLevel } = storeToRefs(useProductStore())
// const shipsInTimeForXmas = ref(false)

// onMounted(getXmasShipping)
// watch(isFramed, getXmasShipping)

const artworkLeadTime = computed(() => {
  return inStock.value && !isSkuProduct.value ? 1 : get(artwork, 'lead_time') || 3
})

const temporaryAdjustment = computed(() => {
  return isPod.value ? 1 : 0
})

const leadTime = computed(() => {
  if (isFramed.value) {
    return artworkLeadTime.value + temporaryAdjustment.value + framingLeadTime.value
  } else {
    return artworkLeadTime.value + temporaryAdjustment.value
  }
})

const inStock = computed(() => {
  return productStockLevel.value > 0
})

// async function getXmasShipping() {
//   await getXmasShippingAvailability(artwork.value.id, isFramed.value, isPod.value).then((e) => {
//     shipsInTimeForXmas.value = !!e.ships_in_time_for_xmas
//   })
// }
</script>
