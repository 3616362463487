import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pb-8"
}
const _hoisted_2 = { class: "text-format mb-4" }
const _hoisted_3 = { class: "divide-y border-t border-b" }
const _hoisted_4 = ["id", "onClick"]
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: "typeset-4 pr-4" }
const _hoisted_7 = { class: "pb-4" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    ($props.heading)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString($props.heading), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.panels, (panel, idx) => {
          return (_openBlock(), _createElementBlock("div", {
            key: idx,
            class: "py-4"
          }, [
            _withDirectives(_createElementVNode("div", {
              id: `accordion-${$setup.kebabCase(panel.heading)}-open`,
              class: "flex justify-between items-baseline cursor-pointer",
              onClick: $event => ($setup.openedIndex = idx)
            }, [
              _createElementVNode("div", null, _toDisplayString(panel.heading), 1),
              _createVNode($setup["KmIcon"], {
                name: "close",
                class: "rotate-45 h-3"
              })
            ], 8, _hoisted_4), [
              [_vShow, $setup.openedIndex !== idx]
            ]),
            _withDirectives(_createElementVNode("div", {
              id: `accordion-${$setup.kebabCase(panel.heading)}-close`,
              class: "flex justify-between items-baseline cursor-pointer pb-6",
              onClick: _cache[0] || (_cache[0] = $event => ($setup.openedIndex = -1))
            }, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(panel.heading), 1),
              _createVNode($setup["KmIcon"], {
                name: "close",
                class: "h-3"
              })
            ], 8, _hoisted_5), [
              [_vShow, $setup.openedIndex === idx]
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_7, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(panel.content)))
            ], 512), [
              [_vShow, $setup.openedIndex === idx]
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}