<template>
  <div :class="{ 'minicart-visible': miniCartVisible, hidden: !miniCartVisible }" class="minicart-container">
    <div class="flex pb-3 mb-5 border-b items-center justify-between">
      <a href="/cart" class="typeset-8">Basket</a>
      <KmCartStatus :item-count="numberOfCartItems" :loading="loading" @click.prevent="$emit('closeCart')" />
    </div>

    <div v-if="cartError" class="bg-red text-white mb-5 p-2">{{ cartError }}</div>

    <div v-if="hasItems">
      <div class="grid grid-cols-12 md:gap-x-8 gap-x-4 md:gap-y-8 gap-y-4 mb-8">
        <KmCartItem
          v-for="(item, idx) in items"
          :key="`cart-item${idx}`"
          :item="item"
          @delete-item="handleDeleteItem"
        ></KmCartItem>
      </div>

      <div class="w-full py-4 border-t flex justify-between">
        <div>Subtotal</div>
        <div>£{{ goodsTotalDisplay }}</div>
      </div>

      <a href="/cart" class="button button-primary w-full" data-test="minicart-checkout-button">Basket</a>
    </div>

    <div v-else>
      <p>Your basket is empty</p>
      <button
        class="button button-primary w-full"
        data-test="minicart-back-to-shop-button"
        @click.prevent="$emit('closeCart')"
      >
        Back to shop
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useCartStore } from '../../stores/useCartStore'
import KmCartItem from './KmCartItem.vue'
import KmCartStatus from './KmCartStatus.vue'
import usePrices from '../../hooks/usePrices'
import type { AddItem } from '../../hooks/usePrices'

const { items, numberOfCartItems, cartError, loading, miniCartVisible } = storeToRefs(useCartStore())
const { deleteFromCart } = useCartStore()
const { displayTotal } = usePrices()

defineEmits<{
  (e: 'closeCart'): void
}>()

const hasItems = computed(() => {
  return numberOfCartItems.value > 0
})

const goodsTotalDisplay = computed(() => {
  return displayTotal(items.value as unknown as AddItem[])
})

function handleDeleteItem(itemId: string) {
  deleteFromCart(itemId)
}
</script>
