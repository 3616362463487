export interface WindowSize {
  height: number
  width: number
}

export default function useWindow() {
  function updateWindowHash(productId: string) {
    if (locationExists()) window.location.replace(`#${productId}`)
  }

  function getWindowSize(): WindowSize {
    return {
      height: document.documentElement.clientHeight,
      width: document.documentElement.clientWidth
    }
  }

  function redirect(path: string): void {
    if (locationExists()) window.location.replace(path)
  }

  function isLocationPath(path: string): boolean {
    return locationExists() ? window.location.pathname === path : false
  }

  function locationExists() {
    return window && window.location
  }

  return {
    getWindowSize,
    isLocationPath,
    redirect,
    updateWindowHash
  }
}
