<template>
  <div
    v-if="galleryItems.length > 0"
    ref="vueSwiper"
    class="swiper lg:-mr-8 mb-12 md:mb-24"
    :data-options="swiperConfig()"
  >
    <div class="flex justify-between mb-8 items-center lg:pr-8">
      <h2 class="mb-4">Gallery</h2>
      <div class="swiper-buttons">
        <div class="swiper-button-prev"><KmIcon name="angle" class="rotate-180" /></div>
        <div class="swiper-button-next"><KmIcon name="angle" /></div>
      </div>
    </div>

    <div class="swiper-wrapper">
      <div
        v-for="(item, idx) in galleryItems"
        :key="idx"
        class="swiper-slide col-width-4"
        :class="item.type == 'video' ? 'self-end' : ''"
      >
        <template v-if="item.type == 'video'">
          <a data-fancybox data-type="iframe" :href="item.url">
            <div :style="item.style" class="aspect-5/3 w-full h-full flex items-center justify-center">
              <div class="z-10 bg-white flex flex-col gap-y-1 items-center justify-center w-14 h-14 md:w-20 md:h-20">
                <KmIcon class="h-4 w-4" name="play" />
                <div class="typeset-8 md:block hidden">Play</div>
              </div>
            </div>
          </a>
        </template>

        <a v-if="item.type == 'image'" :href="item.src" data-fancybox data-touch="false">
          <img :src="item.src" :alt="item.caption" :title="item.caption" />
          <!-- NB: the &nbsp; is necessary to bottom-align images for when captions aren't present -->
        </a>

        <div class="typeset-8 mt-2 min-h-[36px] h-[36px]">{{ item.caption }}&nbsp;</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onUpdated, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useProductStore } from './stores/useProductStore'
import KmIcon from './components/general/KmIcon.vue'
import setupSwiper from '../../utils/swiper'
import useSwiper from './hooks/useSwiper'

const { artwork, activeProduct } = storeToRefs(useProductStore())
const { setProductGalleryItems } = useProductStore()
const { swiperConfig } = useSwiper()
const galleryItems = ref([])
const vueSwiper = ref(null)

onUpdated(() => {
  if (vueSwiper.value) {
    setupSwiper(vueSwiper.value)
  }
})

watch(activeProduct, () => {
  galleryItems.value = setProductGalleryItems(artwork.value, activeProduct.value)
})
</script>
