import { createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-options"]
const _hoisted_2 = { class: "flex justify-between mb-8 items-center lg:pr-8" }
const _hoisted_3 = { class: "swiper-buttons" }
const _hoisted_4 = { class: "swiper-button-prev" }
const _hoisted_5 = { class: "swiper-button-next" }
const _hoisted_6 = { class: "swiper-wrapper" }
const _hoisted_7 = { class: "artwork-card" }
const _hoisted_8 = ["href"]
const _hoisted_9 = ["src", "alt"]
const _hoisted_10 = { class: "artwork-card-info mt-5 md:mt-9" }
const _hoisted_11 = { class: "flex justify-between gap-x-2" }
const _hoisted_12 = ["href"]
const _hoisted_13 = {
  "data-test": "artwork-price",
  class: "mb-4"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.showRecentlyViewed)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref: "vueSwiper",
        class: "swiper lg:-mr-8",
        "data-options": $setup.swiperConfig()
      }, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "mb-4" }, [
            _createElementVNode("a", { href: "/recently-viewed" }, "Recently viewed")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode($setup["KmIcon"], {
                name: "angle",
                class: "rotate-180"
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode($setup["KmIcon"], { name: "angle" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.recentItems, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id,
              class: _normalizeClass(`swiper-slide col-width-${item.col_width}`),
              "data-test-history-item": ""
            }, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("a", {
                  href: `/prints/${item.artwork.id}#${item.item_id}`,
                  class: "artwork-card-img"
                }, [
                  _createElementVNode("img", {
                    src: item.image_url,
                    alt: item.title
                  }, null, 8, _hoisted_9)
                ], 8, _hoisted_8),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("a", {
                      class: "truncate",
                      href: `/prints/${item.artwork.id}#${item.item_id}`
                    }, _toDisplayString(item.title), 9, _hoisted_12),
                    _createVNode($setup["WishListButton"], {
                      "product-id": item.item_id
                    }, null, 8, ["product-id"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, "£" + _toDisplayString($setup.displayPrice(item.price)), 1),
                _createVNode($setup["KmTags"], {
                  tags: item.artwork.tags,
                  "created-at": item.artwork.created_at,
                  clamp: true
                }, null, 8, ["tags", "created-at"])
              ])
            ], 2))
          }), 128))
        ])
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}