<template>
  <div class="col-span-6" style="min-height: 50vw">
    <KmViewInARoom v-if="getViewInARoom" :src="src" />
    <div v-show="!getViewInARoom" class="relative w-full">
      <ProductGalleryMain />
      <div v-show="isTier3" class="tag-xmas top-0" style="bottom: inherit">Christmas exclusive!</div>
    </div>

    <div class="grid grid-cols-2 mb-10">
      <a :href="src" data-fancybox class="button -mr-[1px]">Enlarge</a>

      <button class="button cursor-pointer" @click="showScale">
        <template v-if="getViewInARoom">
          <KmIcon name="expand"></KmIcon>
          <span class="pl-2">View full</span>
        </template>

        <template v-else>
          <KmIcon name="expand"></KmIcon>
          <span class="pl-2">View at scale</span>
        </template>
      </button>
    </div>
  </div>

  <KmModal :full-size="fullSize" :open="zoom" @close="closeZoom">
    <KmImageZoom v-show="zoom" :src="src" />
  </KmModal>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useProductStore } from '../../stores/useProductStore'
import KmIcon from '../../components/general/KmIcon.vue'
import KmImageZoom from '../general/KmImageZoom.vue'
import KmModal from '../general/KmModal.vue'
import KmViewInARoom from '../general/KmViewInARoom.vue'
import ProductGalleryMain from '../../ProductGalleryMain.vue'

defineProps<{
  src: string
}>()

const { getViewInARoom, isTier3 } = storeToRefs(useProductStore())
const fullSize = ref(true)
const productStore = useProductStore()
const zoom = ref(false)

function showScale() {
  productStore.toggleViewInARoom()
}

function closeZoom() {
  zoom.value = false
}
</script>
