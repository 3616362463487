import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full w-full pt-4" }
const _hoisted_2 = { class: "overflow-y-scroll h-full" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex-col absolute right-5 bottom-10" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: _normalizeClass($setup.imageClass),
        src: $props.src,
        alt: "loading"
      }, null, 10, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: _normalizeClass([{ 'opacity-30': $setup.zoomed }, "swiper-button"]),
        onClick: $setup.zoomIn
      }, [
        _createVNode($setup["KmIcon"], {
          name: "close",
          class: "rotate-45"
        })
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass([{ 'opacity-30': !$setup.zoomed }, "swiper-button"]),
        onClick: $setup.zoomOut
      }, [
        _createVNode($setup["KmIcon"], { name: "minus" })
      ], 2)
    ])
  ]))
}