import useHttps from './useHttps'
import type { Cart, CartItem, CartProduct } from '../interfaces/CartInterfaces'

export default function useKubrick() {
  const { get, post, deleteItem } = useHttps()

  function create(ownerId: string): Promise<unknown> {
    const url = `/api/lists`
    return post(url, {
      owner_id: ownerId,
      type: 'cart'
    }).then((response) => {
      return response.data
    })
  }

  function getCart(ownerId: string): Promise<Cart> {
    const url = `/api/lists?owner_id=${ownerId}&type=cart`
    return get(url)
      .then((lists) => {
        if (lists.length === 0) {
          return create(ownerId)
        } else {
          return lists[0]
        }
      })
      .then((cart) => {
        cart.items = cart.items.filter((item: CartItem) => isValid(item))
        return cart
      })
  }

  function isValid(item: CartItem) {
    const STATUS_POSITIVE_BUT_NOT_FOR_SALE = 111
    return item.valid && item.status_id != STATUS_POSITIVE_BUT_NOT_FOR_SALE
  }

  async function addCartItem(listId: string | null, ownerId: string, product: CartProduct) {
    const productType = product.type === 'SkuProduct' ? 'SkuProduct' : 'Product'

    const url = `/api/lists/${listId}/items`
    return post(url, {
      product_id: product.id,
      item_type: productType,
      metadata: product['metadata'] ? product['metadata'] : {},
      owner_id: ownerId
    })
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch(function (error: Error) {
        return Promise.reject(error)
      })
  }

  function deleteCartItem(ownerId: string, listId: string, itemId: string) {
    return deleteItem(`/api/lists/${listId}/items/${itemId}?owner_id=${ownerId}`)
  }

  return {
    getCart,
    addCartItem,
    deleteCartItem
  }
}
