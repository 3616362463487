<template>
  <button v-if="enableDecrease" class="px-2" data-test="cart-quantity-decrease" @click="decrease()">
    <KmIcon name="minus" class="h-3 w-3" />
  </button>
  <button v-else class="px-2 opacity-20 cursor-default">
    <KmIcon name="minus" class="h-3 w-3" />
  </button>

  <input
    class="!w-16 !h-8 text-center"
    :class="inputClass"
    inputmode="numeric"
    name="quantity"
    pattern="[0-9]*"
    type="text"
    :value="itemQuantity"
    @change="submitQuantity"
    @focus="$event.target.select()"
    @keydown="submitOnEnter"
    @model="itemQuantity"
  />

  <button v-if="enableIncrease" class="px-2" data-test="cart-quantity-increase" @click="increase()">
    <KmIcon name="close" class="rotate-45 h-3 w-3" />
  </button>
  <button v-else class="px-2 opacity-20 cursor-default">
    <KmIcon name="close" class="rotate-45 h-3 w-3" />
  </button>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, nextTick } from 'vue'
import KmIcon from '../../components/general/KmIcon.vue'

const props = defineProps<{
  quantity: number
  stockLevel: number
  formId: string
}>()

const itemQuantity = ref()
const maxQuantity = ref()
const inputClass = ref()

onMounted(() => {
  maxQuantity.value = Math.min(props.stockLevel, 100)
  itemQuantity.value = props.quantity
})

const enableDecrease = computed(() => {
  return itemQuantity.value > 1
})

const enableIncrease = computed(() => {
  return itemQuantity.value < maxQuantity.value
})

function submitOnEnter(event: any) {
  if (event.keyCode == 13) {
    event.preventDefault()
    submitQuantity()
  }
}

function submitQuantity() {
  inputClass.value = 'loading indent-20'
  const quantityForm = document.getElementById(props.formId) as HTMLFormElement
  quantityForm?.submit()
}

function decrease() {
  if (itemQuantity.value > 1) {
    itemQuantity.value -= 1
    nextTick(submitQuantity)
  }
}

function increase() {
  if (itemQuantity.value < maxQuantity.value) {
    itemQuantity.value += 1
    nextTick(submitQuantity)
  }
}
</script>
