import { createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = {
  key: 0,
  id: "img",
  class: "pt-10 md:px-10 lg:px-40 fade-in-image"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "tags my-2" }
const _hoisted_7 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: "button flex items-center",
        onClick: $setup.change
      }, [
        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "mr-2" }, "See next", -1)),
        _createVNode($setup["KmIcon"], {
          class: "refresh",
          name: "refresh"
        })
      ]),
      _createElementVNode("button", {
        class: "text-white button bg-black flex items-center",
        onClick: $setup.addToCart
      }, "Add all to basket")
    ]),
    ($setup.dataSet)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.splitDataSet(), (subSet, outerIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              key: outerIndex,
              class: _normalizeClass([$setup.conditionalClass(outerIndex), $setup.conditionalWidths(outerIndex)])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subSet, (item, innerIndex) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: innerIndex,
                  style: _normalizeStyle($setup.widthHeight(item)),
                  class: "m-2"
                }, [
                  _createElementVNode("a", {
                    href: item.url
                  }, [
                    (item.image_url)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createElementVNode("img", {
                            src: item.image_url
                          }, null, 8, _hoisted_5)
                        ]))
                      : _createCommentVNode("", true)
                  ], 8, _hoisted_3),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("a", {
                      href: item.url
                    }, _toDisplayString(item.title), 9, _hoisted_7)
                  ])
                ], 4))
              }), 128))
            ], 2))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}