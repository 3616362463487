<template>
  <div v-if="frames" class="grid grid-cols-1 md:grid-cols-2 gap-8">
    <div v-for="frame in frames" :key="frame.id">
      <div class="flex">
        <img :src="frame.image_url" alt="" class="h-20 w-20 mr-6" />
        <div>
          <p class="typeset-8">{{ frame.title }}</p>
          <p class="leading-6">{{ frame.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useProductStore } from '../../stores/useProductStore'

const productStore = useProductStore()
const { activeOptions } = storeToRefs(productStore)

const frames = computed(() => activeOptions.value.frames.filter((frame) => frame.frame_id > 0))
</script>
