<template>
  <div class="mx-auto text-center -mb-10">
    <img
      :src="src"
      :style="{ width: `${widthPx}px` }"
      alt="loading"
      class="mx-auto inline-block align-middle cursor-pointer"
      @click="productStore.toggleViewInARoom()"
    />
  </div>

  <div class="flex justify-end m-5">
    <div class="w-[112px] md:w-[170px] text-center">
      <div id="chair" v-html="chair"></div>
      <div class="border-l border-r h-4 flex flex-col divide-y">
        <div class="h-1/2"><!-- all this hacky border nonsense can be replaced --></div>
        <div class="h-1/2"><!-- by a few cleverly placed svg elements. but until then... --></div>
      </div>
      50cm
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, onUpdated, ref } from 'vue'
import { useProductStore } from '../../stores/useProductStore'
import chair from '../../../../../../assets/svg/chair.svg'
import { storeToRefs } from 'pinia'

const productStore = useProductStore()
const widthPx = ref(0)
const { activeProduct } = storeToRefs(productStore)

defineProps<{
  src: string
}>()

onMounted(() => {
  resizeImage()
  window.addEventListener('resize', resizeImage)
})

onUpdated(() => {
  resizeImage()
})

function productWidth() {
  return activeProduct?.value?.dimensions?.width
}

function resizeImage() {
  const chairWidthMm = 500
  const element = document.querySelector('#chair path')
  // TODO: there seems to be a bug with mobile Safari that prevents this element from being found
  // But we've not been able to reproduce it
  if (element !== null) {
    const positionInfo = element.getBoundingClientRect()
    const chairWidthPx = positionInfo.width

    const productWidthMm = productWidth()
    const pixelsPerMm = chairWidthPx / chairWidthMm
    widthPx.value = pixelsPerMm * productWidthMm
  }
}
</script>
