import { defineStore } from 'pinia'
import type { Cart, CartProduct } from '../interfaces/CartInterfaces'
import { sumBy } from 'lodash-es'
import useKubrick from '../hooks/useKubrick'
import { useSessionStore } from './useSessionStore'

export const useCartStore = defineStore('cart', {
  state: () => ({
    cart: null as string | null,
    items: [] as CartProduct[],
    cartError: '',
    total: 0,
    loading: false,
    adding: false,
    added: false,
    miniCartVisible: false
  }),

  getters: {
    numberOfCartItems: (state) => sumBy(state.items, 'quantity')
  },

  actions: {
    async fetchCart() {
      this.loading = true
      const { getOwnerId } = useSessionStore()
      const { getCart } = useKubrick()

      const ownerId = await getOwnerId()
      if (ownerId) {
        getCart(ownerId)
          .then((cart: Cart) => {
            this.cart = cart.id
            this.items = cart.items
          })
          .finally(() => (this.loading = false))
      }
    },

    async addToCart(item: CartProduct) {
      const { addCartItem } = useKubrick()
      const { getOwnerId } = useSessionStore()
      const cartItem = { ...item, quantity: 1 }
      const ownerId = await getOwnerId()
      this.adding = true
      this.added = false

      if (ownerId) {
        await addCartItem(this.cart, ownerId, cartItem)
        this.fetchCart()
          .finally(() => ((this.added = true), (this.adding = false)))
          .catch((error) => {
            console.log('cart-store: failed to fetch cart', error)
          })
      } else {
        console.log('cart-store: could not find ownerId')
      }
    },

    async deleteFromCart(itemId: string) {
      const { getOwnerId } = useSessionStore()
      const ownerId = await getOwnerId()
      const { deleteCartItem } = useKubrick()

      if (this.cart && ownerId) {
        await deleteCartItem(this.cart, ownerId, itemId).catch((error) => {
          console.log('cart-store: Delete Failed - report to user?', error)
        })
        this.fetchCart().catch((error) => {
          console.log('cart-store: failed to fetch cart', error)
        })
      }
    },

    async toggleMiniCartVisibility() {
      this.miniCartVisible = !this.miniCartVisible
    },

    addCartError(error: string) {
      this.cartError = error
      this.adding = false
    },

    clearCartErrors() {
      this.cartError = ''
    }
  }
})
