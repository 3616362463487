import { sumBy } from 'lodash-es'

export interface AddItem {
  quantity: number
  price: number
}

export default function usePrices(): {
  displayPrice: (price: number) => string
  displayTotal: (items: AddItem[]) => string
} {
  function displayPrice(price: number) {
    return (price / 100).toLocaleString()
  }

  function displayTotal(items: AddItem[]): string {
    const total = sumBy(items, (item) => item.price * item.quantity)
    return displayPrice(total)
  }

  return {
    displayPrice,
    displayTotal
  }
}
