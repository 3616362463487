import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("a", {
    href: $setup.enquireHref,
    class: "button w-full",
    "data-fancybox": "",
    "data-test": "enquiry-button",
    "data-touch": "false",
    "data-type": "ajax"
  }, " Make an enquiry ", 8, _hoisted_1))
}