import { defineStore } from 'pinia'
import { first } from 'lodash-es'
import { useSessionStore } from './useSessionStore'
import type { RecentlyViewed } from '../interfaces/HistoryInterfaces'
import useHttps from '../hooks/useHttps'

export const useHistoryStore = defineStore('customer', {
  state: () => ({
    list: {} as RecentlyViewed | undefined
  }),

  actions: {
    async fetchRecentlyViewed() {
      const { getOwnerId } = useSessionStore()
      const owner_id = await getOwnerId()

      if (owner_id) {
        return useHttps()
          .get(`/api/lists?owner_id=${owner_id}&type=history`)
          .then((res) => {
            this.list = first(res)

            // TODO: it's wasteful to create a new list every time we try to fetch one
            // it would be much better to do this only when someone tries to add an item to a wish list
            if (this.list === undefined) {
              this.createList(owner_id)
            }
          })
          .finally(() => this.list)
      }
    },

    async updateRecentlyViewed(productId: string) {
      const { getOwnerId } = useSessionStore()
      const owner_id = await getOwnerId()

      if (owner_id && this.list) {
        const data = {
          product_id: productId,
          item_type: 'Product',
          id: this.list.id,
          owner_id: owner_id
        }
        const result = await useHttps().post(`/api/lists/${this.list.id}/items`, data)
        this.list = result.data
      }
    },

    async createList(owner_id: string) {
      this.list = await useHttps()
        .post(`/api/lists?owner_id=${owner_id}&type=history`, {})
        .then(function (response) {
          return response.data
        })
    }
  }
})
