import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["value", "onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["relative", { 'km-select': $setup.open }])
    }, [
      _createElementVNode("div", {
        style: _normalizeStyle($setup.listStyle),
        class: _normalizeClass([{ 'shadow-black shadow-lg': $setup.open }, "absolute select-none cursor-pointer bg-white whitespace-nowrap -mt-2 py-2"])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (option, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            value: option.value,
            class: _normalizeClass(["flex px-3", { 'hover:bg-grey': $setup.open, hidden: !option.selected && !$setup.open }]),
            onClick: $event => ($setup.selectOption(option), ($setup.open = !$setup.open))
          }, [
            _createElementVNode("div", null, _toDisplayString(option.description), 1),
            _createElementVNode("div", {
              class: _normalizeClass(["px-2", { hidden: $setup.open || !option.selected }])
            }, [
              _createVNode($setup["KmIcon"], {
                name: "chevron",
                class: "km-rotate-start h-[14px] w-[14px] align-middle"
              })
            ], 2)
          ], 10, _hoisted_1))
        }), 128))
      ], 6)
    ], 2),
    ($setup.open)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "modal-bg km-select-bg",
          onClick: _cache[0] || (_cache[0] = _withModifiers($event => ($setup.open = false), ["stop"]))
        }))
      : _createCommentVNode("", true)
  ], 64))
}