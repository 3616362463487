import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createVNode as _createVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center border-b mb-10 py-4" }
const _hoisted_2 = { class: "typeset-8" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.open)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "body"
      }, [
        _createElementVNode("div", {
          id: "modal-bg",
          class: "modal-bg",
          onClick: _withModifiers($setup.closeModal, ["stop"])
        }),
        _createElementVNode("div", {
          id: "modal-content",
          class: _normalizeClass([$setup.modalClass, "modal-content absolute bg-white py-4 px-4 md:px-8 pb-8 max-h-full overflow-y-auto"]),
          onKeyup: _withKeys($setup.closeModal, ["esc"])
        }, [
          _renderSlot(_ctx.$slots, "header", {}, () => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("p", _hoisted_2, _toDisplayString($props.title) + " ", 1),
              _createVNode($setup["KmIcon"], {
                class: "h-4 w-4 cursor-pointer",
                name: "close",
                onClick: $setup.closeModal
              })
            ])
          ]),
          _renderSlot(_ctx.$slots, "default")
        ], 34)
      ]))
    : _createCommentVNode("", true)
}