import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "pt-4" }
const _hoisted_3 = ["href"]
const _hoisted_4 = {
  key: 1,
  class: "loading h-12 -mb-8"
}
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "mt-5" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "form-container my-5" }
const _hoisted_9 = { class: "flex gap-x-f flex-row gap-x-8" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "flex border-b py-3" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.itemAdded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("p", _hoisted_2, "Item added to ‘" + _toDisplayString($setup.selectedList.description) + "’", 1),
          _createElementVNode("a", {
            href: $setup.wishlistHref(),
            class: "button w-full"
          }, "Go to wish list", 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    ($setup.listsLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4))
      : _createCommentVNode("", true),
    (!$setup.itemAdded && !$setup.listsLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, _toDisplayString($setup.message), 1),
          ($setup.addingNew || !$setup.hasWishLists)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _cache[3] || (_cache[3] = _createElementVNode("label", null, "Title", -1)),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.listTitle) = $event)),
                    placeholder: "Enter title",
                    type: "text"
                  }, null, 512), [
                    [_vModelText, $setup.listTitle]
                  ]),
                  _cache[4] || (_cache[4] = _createElementVNode("label", null, "Description", -1)),
                  _withDirectives(_createElementVNode("textarea", {
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.listDescription) = $event)),
                    placeholder: "Enter description",
                    type: "text"
                  }, null, 512), [
                    [_vModelText, $setup.listDescription]
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  ($setup.hasWishLists)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "w-full button button-tall",
                        onClick: _cache[2] || (_cache[2] = $event => ($setup.addingNew = false))
                      }, "Cancel"))
                    : _createCommentVNode("", true),
                  _createElementVNode("button", {
                    class: "w-full button button-primary",
                    onClick: $setup.createWishList
                  }, "Create wish list")
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _cache[5] || (_cache[5] = _createElementVNode("div", null, "List:", -1)),
                  ($setup.hasWishLists)
                    ? (_openBlock(), _createBlock($setup["KmSelectBox"], {
                        key: 0,
                        options: $setup.wishListOptions(),
                        onOptionUpdate: $setup.updateOption
                      }, null, 8, ["options"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("button", {
                  class: "button button-primary w-full mt-8",
                  onClick: $setup.addItemToWishList
                }, "Add")
              ]))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}