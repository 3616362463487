import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-5 gap-x-9 gap-y-3" }
const _hoisted_2 = { class: "col-span-1" }
const _hoisted_3 = { class: "col-span-4" }
const _hoisted_4 = {
  key: 0,
  class: "col-span-3 typeset-8 mt-4"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.displayMetaData, (data, idx) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: idx }, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(data.key), 1),
          _createElementVNode("div", _hoisted_3, _toDisplayString(data.value), 1)
        ], 64))
      }), 128))
    ]),
    ($setup.showProductDisclaimer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[0] || (_cache[0] = [
          _createElementVNode("p", null, "Actual product may differ slightly to image above. Some prints contain writing in the border area.", -1)
        ])))
      : _createCommentVNode("", true)
  ], 64))
}