<template>
  <a
    :href="enquireHref"
    class="button w-full"
    data-fancybox
    data-test="enquiry-button"
    data-touch="false"
    data-type="ajax"
  >
    Make an enquiry
  </a>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps<{
  productId: string
  productTitle: string
}>()

const enquireHref = computed(() => {
  const printTitle = encodeURI(props.productTitle)

  return `/help/enquire-form?print_title=${printTitle}&product_id=${props.productId}`
})
</script>
