import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode($setup["KmIcon"], {
      name: "heart",
      class: "h-4 w-4 -mb-1 cursor-pointer",
      onClick: _cache[0] || (_cache[0] = $event => ($setup.addProduct()))
    })
  ]))
}