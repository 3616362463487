import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["xlink:href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(["km-icon", 'icon-' + _ctx.name]),
    style: _normalizeStyle(_ctx.cssStyle)
  }, [
    _createElementVNode("use", {
      "xlink:href": '#icon-' + _ctx.name
    }, null, 8, _hoisted_1)
  ], 6))
}