import { createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withModifiers as _withModifiers, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode($setup["KmCartStatus"], {
        "item-count": $setup.numberOfCartItems,
        loading: $setup.loading,
        onClicked: $setup.cartClicked
      }, null, 8, ["item-count", "loading"])
    ]),
    _createVNode($setup["KmMiniCart"], {
      visible: $setup.miniCartVisible,
      onCloseCart: $setup.toggleMiniCartVisibility
    }, null, 8, ["visible", "onCloseCart"]),
    _withDirectives(_createElementVNode("div", {
      class: "modal-bg",
      onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => ($setup.toggleMiniCartVisibility && $setup.toggleMiniCartVisibility(...args)), ["prevent"]))
    }, null, 512), [
      [_vShow, $setup.miniCartVisible]
    ])
  ], 64))
}