<template>
  <div></div>
</template>

<script lang="ts" setup>
import { watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useProductStore } from './stores/useProductStore'
const { activeProduct, artwork } = storeToRefs(useProductStore())

watch(activeProduct, () => {
  const script = document.getElementById('json-ld')
  if (script) {
    script.innerText = JSON.stringify(jsonProduct())
  } else {
    const script = document.createElement('script')
    script.setAttribute('id', 'json-ld')
    script.setAttribute('type', 'application/ld+json')
    script.innerText = JSON.stringify(jsonProduct())
    document.body.appendChild(script)
  }

  updateMetaPriceTag()
})

// NB: this is in the wrong place. it has nothing to do with json ld.
// but for now, it's the quickest way to get the change live
function updateMetaPriceTag() {
  const tags = document.querySelectorAll('meta[property="product:price:amount"]')
  for (let i = 0; i < tags.length; i++) {
    tags[i].content = activeProduct.value.price / 100
  }
}

function jsonProduct(): object | null {
  const ld = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: artwork.value.title,
    brand: artwork.value.artist.full_name,
    sku: activeProduct.value.id,
    image: activeProduct.value.images[0],
    itemCondition: 'https://schema.org/NewCondition',
    offers: {
      '@type': 'Offer',
      price: activeProduct.value.price / 100,
      priceCurrency: 'GBP',
      priceValidUntil: priceValidUntil(),
      availability: 'https://schema.org/InStock',
      shippingDetails: {
        '@type': 'OfferShippingDetails',
        shippingRate: {
          '@type': 'MonetaryAmount',
          value: 8.95,
          currency: 'GBP'
        }
      },
      hasMerchantReturnPolicy: {
        '@type': 'MerchantReturnPolicy',
        applicableCountry: 'GB',
        returnPolicyCategory: 'https://schema.org/MerchantReturnFiniteReturnWindow',
        merchantReturnDays: 30,
        returnMethod: 'https://schema.org/ReturnByMail',
        returnFees: 'https://schema.org/FreeReturn'
      }
    }
  }

  if (artwork.value.customer_rating > 0) {
    ld.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: artwork.value.customer_rating,
      reviewCount: artwork.value.customer_rating_num
    }
  }

  return ld
}

function priceValidUntil() {
  const futureDate = new Date()
  futureDate.setMonth(futureDate.getMonth() + 1)

  return futureDate.toISOString().slice(0, 10)
}
</script>
