import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "grid grid-cols-5 gap-x-6 border-t py-4 divide-x items-center" }
const _hoisted_3 = { class: "col-span-3" }
const _hoisted_4 = { class: "typeset-8" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "col-span-2 pl-2" }
const _hoisted_7 = ["href"]
const _hoisted_8 = ["data-src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.comp.slug)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createTextVNode(_toDisplayString($setup.comp.text) + " ", 1),
              _createElementVNode("a", {
                href: $setup.comp.url
              }, _toDisplayString($setup.comp.title.toUpperCase()), 9, _hoisted_5)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("a", {
              href: $setup.comp.url
            }, [
              _createElementVNode("img", {
                "data-src": $setup.comp.image,
                class: "lazyload mx-auto max-h-10"
              }, null, 8, _hoisted_8)
            ], 8, _hoisted_7)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}