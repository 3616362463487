<template>
  <div>
    <a
      id="pinterest-button"
      class="hidden"
      :data-pin-custom="true"
      :data-pin-media="getSrc()"
      :href="pinterestHref()"
      @click.prevent.stop="savePin()"
    >
      <KmIcon name="share-p" class="hover:text-error cursor-pointer -mb-1" />
    </a>
  </div>
</template>

<script lang="ts" setup>
import KmIcon from './components/general/KmIcon.vue'
import { useProductStore } from './stores/useProductStore'
import { storeToRefs } from 'pinia'
const productStore = useProductStore()
const { activeProduct } = storeToRefs(productStore)

function savePin() {
  const url = `https://www.kingandmcgaw.com/${location.pathname}#${activeProduct.id}`

  window.PinUtils.pinOne({
    url: url,
    media: pinterestImgSrc(),
    description: 'Save to Pinterest'
  })
}

function pinterestHref() {
  const url = encodeURI(`https://www.kingandmcgaw.com/${location.pathname}#${activeProduct.id}`)
  const media = encodeURI(pinterestImgSrc())

  return `https://www.pinterest.com/pin/create/button/?url=${url}&media=${media}&description=Save+to+Pinterest`
}

function pinterestImgSrc() {
  // NB: img.kingandmcgaw.com is proxied through CloudFlare which doesn't allow pinning
  return getSrc().replace(/img.kingandmcgaw.com/, 'v1.imgix.net')
}

function getSrc(): string {
  return activeProduct.value ? activeProduct.value.image_url : ''
}
</script>
